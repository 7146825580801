<template>
  <div class="open-window" id="openWindow">
    <a-popover 
    v-model="popvisible"
    trigger="click"
    placement="right" 
    :getPopupContainer="
      triggerNode => {
        return triggerNode.parentNode.parentNode.parentNode.parentNode;
      }
    "
    :destroyTooltipOnHide="true"
    overlayClassName="slef-pop">
      <template slot="title">
        <a-row type="flex" justify="space-between">
          <a-col :span="8" class="map-title">
            维保明细
          </a-col>
          <a-col :span="2" @click="hide">
            <a-icon type="close" style="color: #fff;" />
          </a-col>
        </a-row>
      </template>
      <template slot="content">
        <div class="map-hr"></div>
        <div class="info-view">
          <div>维保单位：<span :title="maintenancedepidDetail" class="value-text">{{maintenancedepidDetail}}</span></div>
          <div>维保工：<span :title="popDatas.username" class="value-text">{{popDatas.username}}</span></div>
          <div>上传时间：<span :title="popDatas.maintenacedate" class="value-text">{{popDatas.maintenacedate}}</span></div>
          <div>历史记录</div>
          <a-table :columns="tableColumns2" :data-source="tableData2" row-key="maintenanitemrecordid" :loading="tableLoading2" :pagination="false">
            <span slot="index" slot-scope="value, record, index">
              {{index+1}}
            </span>
            <span slot="maintenanitemstatus" slot-scope="value">
              {{maintenanceitemStatusMap[value]}}
            </span>
          </a-table>
        </div>
      </template>
    </a-popover>
    <div class="map-title">维保信息</div>
    <div class="map-hr"></div>
    <div class="info-view">
      <div>注册代码：<span :title="liftInfos.liftnum" class="value-text">{{liftInfos.liftnum}}</span></div>
      <div>使用单位：<span :title="userdepidDetail" class="value-text">{{userdepidDetail}}</span></div>
      <div>维保单位：<span :title="maintenancedepidDetail" class="value-text">{{maintenancedepidDetail}}</span></div>
      <div>上次维保：<span :title="finishtime" class="value-text">{{finishtime}}</span></div>
      <div>上次检验：<span :title="examinetime" class="value-text">{{examinetime}}</span></div>
      <div>历史记录</div>
      <a-table :columns="tableColumns" :data-source="tableData" row-key="maintenancorderid" :loading="tableLoading"  style="margin-top: 16px;" :pagination="false">
        <span slot="maintenancescheduletype" slot-scope="value">
          {{maintenanceTypesMap[value] || value}}
        </span>
        <span slot="operation" slot-scope="value, record, index">
          <a-button type="link" size="small" @click="detail(value, record)">详情</a-button>
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['liftid','fontSize'],
  data() {
    return {
      moment,
      popvisible:false,
      popDatas:{
        username:"",
        maintenacedate:""
      },
      liftInfos:{
        liftnum:'',
      },
      finishtime:"",
      examinetime:"",
      userdepidDetail:"",
      maintenancedepidDetail:"",
      maintenanceTypesMap:{},
      tableData: [],
      tableLoading: false,
      tableColumns: [
        {
          title: '维保日期',
          dataIndex: 'maintenacedate',
          key: 'maintenacedate',
          ellipsis: true,
        },
        {
          title: '类型',
          dataIndex: 'maintenancescheduletype',
          key: 'maintenancescheduletype',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenancescheduletype' }
        },{
          title: '维修工',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        }
        ,{
          width:80,
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      tableData2: [],
      tableLoading2: false,
      tableColumns2: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          align: 'center',
          scopedSlots: { customRender: 'index' }
        },
        {
          width:120,
          title: '项目名称',
          dataIndex: 'itemname',
          key: 'itemname',
          ellipsis: true,
        },
        {
          title: '状态',
          dataIndex: 'maintenanitemstatus',
          key: 'maintenanitemstatus',
          width: 60,
          scopedSlots: { customRender: 'maintenanitemstatus' }
        },
        {
          title: '检查结果',
          dataIndex: 'resultdesc',
          key: 'resultdesc',
          ellipsis: true,
        },
      ],
      
    }
  },
  watch: {
    liftid(newValue, oldValue) {
      if(newValue){
        this.getListInfos()
      }
    }
  },
  created() {
    let result = {
      0:'维保类型'
    };
  },
  methods: {
    hide(){
      this.popvisible = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.open-window{
  position: relative;
  min-width: 400px;
}
.map-title{
  font-size: 16px;
}
.info-view {
  margin-left: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 430px;
  height: 300px;
  overflow: auto;
  color:#fff;
}
.info-view>div{
  color: #90a5b6;
  line-height: 26px;
  font-size: 15px;
}
.info-view::-webkit-scrollbar {
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.info-view::-webkit-scrollbar-thumb {  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: #ebebeb;
}
.info-view::-webkit-scrollbar-track {  /*滚动条里面轨道*/
  /* -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2); */
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.45);
}

</style>

<style  lang="scss">
  .open-window td,.open-window th{
    border:none!important;
    color: #90a5b6!important;
  }
  .open-window th{
    background-color: #383838!important;
  }
  .open-window tr:hover td{
    background-color: transparent!important;
  }
</style>