<!--大数据视图主页面-->
<template>
  <div class="lot-big-data" id="supMap">
    <div class="big-bg">
      <div class="header">
        <div class="logo">
          <img src="~@/assets/image/logo.png">
          <span>优湖科技</span>
        </div>
        <div class="flex" style="font-size: 35px;margin-left: 7%">{{bigtitle}}</div>
        <div class="fix-right">
          <div class="fr" style="cursor: pointer;">
            <a-icon v-if="!fullScreen" type="fullscreen" @click="requestFullScreen('enter')" />
            <a-icon v-else type="fullscreen-exit" @click="requestFullScreen" />
          </div>
          <div class="fr">
            <div>{{currArr[0]}}</div>
            <div class="lg-size">{{currArr[1]}}</div>
          </div>
        </div>
      </div>
      <div class="left">
        <MiddleLeft :userDepId="selectedUserdep"/>
      </div>
      <div class="center">
        <MiddleTop @tab-click="handletabs" @select-opt="handleOpt" />
      </div>
      <div class="right">
        <MiddleRight :userDepId="selectedUserdep"/>
      </div>
    </div>
    <scene-template-detail :visible.sync="modalVisible" :detailData="modalDetailData"></scene-template-detail>
  </div>
</template>
<script>
import Vue from "vue";
import moment from 'moment';
import store from '@/store'
import { mapState } from 'vuex'
import MiddleTop from "../MiddleTop/Index";
import MiddleLeft from "../MiddleLeft/Index";
import MiddleRight from "../MiddleRight/Index";
import OpenWindow from "../components/openWindow/index";
import OpenWindow1 from "../components/openWindow/main";
import online from "@/assets/image/map/online.png";
import offline from "@/assets/image/map/offline.png";
import someonline from "@/assets/image/map/someonline.png";
import {getUserByUserid} from "A/login";
import {getCache} from "U/index";
import {getMonitorPointinfoById, getMonitorPointListByCondition} from "A/monitoring";
import SceneTemplateDetail from "C/modals/SceneTemplateDetail";
export default {
  name: 'superviseBigData',
  components: {
    MiddleTop,
    MiddleLeft,
    MiddleRight,
    SceneTemplateDetail,
  },
  data() {
    return {
      modalVisible: false,
      modalDetailData: {
        scenetemplateid:'',
        templatename:'',
      },
      Map:null,
      currArr:[],
      dateArr:[],
      selectedArea:'',
      selectedPlace:'',
      selectedLiftyear:'',
      selectedDevicemodelid:'',
      selectedMonitorpoint:'',
      selectedUserdep:'',
      bigtitle:'全域监管视图',
      usertype:'',
      mainDate:{
        liftnum:0,
        maincontrolnum:0,
        sensornum:0,
        statuscount:{}
      },
      timer1:null,
      timer2:null,
      cur:0,//控制展示什么模板信息
      vm:null,
      token:'',
    }
  },
  computed: {
    ...mapState({
      fullScreen: state => state.main.fullScreen,
      fontSize: state => state.main.fontSize,
      collapsed: state => state.main.collapsed,
    })
  },
  watch: {
    fullScreen(newValue){
      this.vm.$refs.openWindow.resizeJ()
    },
    collapsed(newValue, oldValue) {
      if(this.vm){
      this.vm.$refs.openWindow.resizeJ()
      }
    },
    'selectedUserdep':function (){
    },
  },
  created () {
    // if(this.$route.params){
    //   this.requestFullScreen('enter')
    // }
    this.mapParams = {}
    this.getTitle()
  },
  mounted () {
    this.initDate()
    this.getAllLifts()
    function screenChange(e){
      store.commit('main/setFullScreen', document.fullscreen)
    }
    if(document.body.clientWidth>1360){
      store.commit('main/setFontSize', 18)
    }
    window.addEventListener('fullscreenchange',screenChange)
    this.$once('hook:beforeDestroy',()=>{
      clearTimeout(this.timer1)
      clearTimeout(this.timer2)
      window.removeEventListener('fullscreenchange',screenChange)
    })
  },
  methods: {
    getTitle(){
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.userid=logininfo.usersid;
      }
        let params = {
          userid: this.userid,
        }
        getUserByUserid(params).then(res => {
          if (res && res.returncode == '0') {
            if(res.item.bigtitle!=''&&res.item.bigtitle!=null) {
              this.bigtitle = res.item.bigtitle;
              this.usertype = res.item.usertype;
            }
          }
        })
    },
    handleOpt(type,val){
      if(type=="areacode"){
        this.selectedArea=val;
      }else if(type=="placeid"){
        this.selectedPlace=val;
      }else if(type=="monitorpointname"){
        this.selectedMonitorpoint=val;
      }else if(type=="userdepid"){
        this.selectedUserdep=val;
      }
      if(val==='0'){
        delete this.mapParams[type]
      }else{
        this.mapParams = Object.assign(this.mapParams,{[type]:val})
      }
      this.getMapDatas()
    },
    //城市定位点
    getMapDatas(){
      var num = [];
      var style = {
        url: online,
        anchor: new AMap.Pixel(6, 6),
        size: new AMap.Size(14.5, 20.25),
        imageSize: new AMap.Size(14.5, 20.25),
      }
      var style1 = {
        url: offline,
        anchor: new AMap.Pixel(6, 6),
        size: new AMap.Size(14.5, 20.25),
        imageSize: new AMap.Size(14.5, 20.25),
      }
      var style2 = {
        url: someonline,
        anchor: new AMap.Pixel(6, 6),
        size: new AMap.Size(14.5, 20.25),
        imageSize: new AMap.Size(14.5, 20.25),
      }
      var styleList=[style,style1,style2]
      getMonitorPointListByCondition({
        userdepsid:this.selectedUserdep,
        linkclient:1,
        // ...this.mapParams,
        // ismonitorpoint:1,
      }).then(res=>{
        if(res && res.returncode == '0'){
          if(this.hailaingdian){
            this.hailaingdian.off('click', this.markerClick)
            this.hailaingdian.clear()
            this.hailaingdian = null
          }
          const lists = res.item.filter(v=>v.coordinatey&&v.coordinatex&&v.coordinatey>-90&&v.coordinatey<90&&v.coordinatex>-180&&v.coordinatex<180)
          this.hailaingdian = new AMap.MassMarks(lists.map(v=>({
            ...v,
            lnglat:[v.coordinatex,v.coordinatey],
            style:v.onlineorder=="4"?0:v.onlineorder=="0"?1:2
          })),{
            opacity: 0.8,
            zIndex: 111,
            cursor: 'pointer',
            style: styleList
          });
          // this.hailaingdian.setData(lists.map(v=>({
          //   ...v,
          //   lnglat:[v.coordinatex,v.coordinatey],
          //   style:v.isonline=="在线"?0:v.isonline=="离线"?1:2
          // })),)
              // var marker = new AMap.Marker({content: ' ', map: this.Map});
          this.hailaingdian.setMap(this.Map);
          this.hailaingdian.on('click', this.markerClick)
          num=[lists[0].coordinatex,lists[0].coordinatey]
          this.hailaingdian.on('mouseover',mouseoverfunc)
          this.hailaingdian.on('mouseout',mouseoutfunc)
        }
        this.Map.on('container', {
          resizeEnable: true,
          center: [106.397428, 49.90923],
          zoom: 10
        });
        this.Map.setZoom(10)
        this.Map.setCenter(num)

        var that=this;
        //滑过事件
        function mouseoverfunc(e) {
          //设置弹出信息窗口
          showOrHideLabel(e.data.lnglat,e.data,'mouseover',that.Map);
        }
        //滑出事件
        function mouseoutfunc(e) {
          //删掉信息窗口
          showOrHideLabel(e.data.lnglat,e.data,'mouseout',that.Map);
        }
        /**打开对应的起点或终点marker需要显示的信息  markerSet为marker的set集合*/
        function showOrHideLabel(lnglat,data,mousetype, map) {
          if (mousetype == 'mouseover') {
            openInfo(lnglat,data,map);
          } else if (mousetype == 'mouseout') {
            closeInfo();
          }
        }

        let infoWindow;
        //在指定位置打开信息窗体
        function openInfo(lnglat,data,map) {
          //构建信息窗体中显示的内容
          // <div class="map-content">监控点ID：<span>${data.monitorpointnum}</span></div>
          // <div class="map-content">监控点名称：<span>${data.monitorpointname}</span></div>
          let info=`<div><h3 style="font-size: 18px;color: white">监控点信息</h3>
          <div class="map-content">监控点名称：<span>${data.userdepname}</span></div>
          <div class="map-content">地址：<span>${data.address}</span></div>
          </div>`
          infoWindow = new AMap.InfoWindow({
            anchor: 'middle-right',
            offset: new AMap.Pixel(3, 0),
            content: info  //使用默认信息窗体框样式，显示信息内容
          });
          infoWindow.open(map, lnglat);
        }
        function closeInfo() {
          infoWindow.close();
        }
      })
    },
    //地图
    async getAllLifts(){
      const AMap = window.AMap  //a844d437537380d2fb1afac3cdbce520
      this.Map = new AMap.Map('supMap',{
        mapStyle: 'amap://styles/a844d437537380d2fb1afac3cdbce520', //设置地图的显示样式
      });
      this.Map.on("dragging",(e)=>{
        if(this.vm&&this.cur*1!==1){
          this.vm.$refs.openWindow.resizeJ()
        }
      })
      this.getMapDatas()
    },
    handletabs(idx){
      this.cur = idx
      this.infoWindow&&this.infoWindow.close()
    },
    markerClick(e){
      this.Map?.clearInfoWindow()
      this.openWindow(e.data)
    },
    async openWindow(o){
      const AMap = window.AMap
      const content = await this.getMonitorinfos(o.monitorpointid)
      if(content){
        const close=()=>{
          this.vm.$refs.openWindow.destory()
          console.log("弹窗关闭了")
        }
        if(this.infoWindow){
          this.infoWindow.off('close',close)
        }
        this.infoWindow = new AMap.InfoWindow({
          anchor: this.cur*1===1?'middle-right':'middle-left',
          offset: this.cur*1===1?new AMap.Pixel(-16, 4):new AMap.Pixel(16, 4),
          content,  //使用默认信息窗体框样式，显示信息内容
        });
        this.infoWindow.on('close',close)
        this.Map.setZoomAndCenter(10,o.lnglat)
        this.infoWindow.open(this.Map,o.lnglat);
      }
    },
    async getMonitorinfos(monitorpointid){
      let that = this
      let Profile = null
      if(this.cur*1===1){
        const fs = this.fontSize
       Profile = Vue.extend({
            template:'<open-window1 :fontSize="fontSize" />',
            components: {
              OpenWindow1,
            },
            data: function () {
              return {
                monitorpointid:monitorpointid,
                fontSize:fs,
              }
            }
          })
      }else{
        const res = await getMonitorPointinfoById({monitorpointid:monitorpointid})
        if(res.returncode==='0'){
          const {monitorpointnum,monitorpointname} = res.item
          const fs = this.fontSize
          Profile = Vue.extend({
            template: '<open-window ref="openWindow" :title="title" :list="list"  :monitorpointnum="monitorpointnum" :fontSize="fontSize"  @sceneDetails="sceneDetail"/>',
            components: {
              OpenWindow,
            },
            data: function () {
              return {
                title:monitorpointname,
                list:res.item.list,
                monitorpointid:monitorpointid,
                monitorpointnum: monitorpointnum,
                fontSize:fs,
              }
            },
            methods:{
              sceneDetail(msg){
                that.modalVisible=msg.modalVisible
                that.modalDetailData=msg.modalDetailData
              },
            }
          })
        }
      }
      const extendComponent = new Profile()
      this.vm = extendComponent.$mount()
      return this.vm.$el
    },
    initDate(){
      this.currArr = this.showDT()
      let timer = setInterval(()=>{
        this.currArr = this.showDT()
      },1000);
      this.$once('hook:beforeDestroy',()=>{
        clearInterval(timer);
        timer = null;
      })
    },
    initDateArr(date){
      let arr = []
      arr.push(moment(date?new Date(date):new Date()).subtract(2,'months').format('YYYY.MM'))
      arr.push(moment(date?new Date(date):new Date()).subtract(1,'months').format('YYYY.MM'))
      arr.push(moment(date?new Date(date):new Date()).format('YYYY.MM'))
      let date1 = moment(date?new Date(date):new Date()).subtract(-1,'months')
      arr.push(date1<moment()?date1.format('YYYY.MM'):'')
      let date2 = moment(date?new Date(date):new Date()).subtract(-2,'months')
      arr.push(date2<moment()?date2.format('YYYY.MM'):'')
      let currDate = moment(date?new Date(date):new Date()).format('YYYYMM')
      this.dateArr = arr
    },
    showDT() {
      return [moment().format("YYYY.MM.DD"),moment().format("HH:mm:ss")]
    },
    // 控制是否全屏展示
    requestFullScreen(type) {
      if(type==='enter'){
        let element = document.body
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        }
      }else{
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .datav{
    position: absolute;
    width: auto;
    z-index: 999999;
  }
  .lot-big-data {
    position: relative;
    font-size: 16px;
    color: #fff;
    height: 100%;
    padding: 0;
    /* background-color: rgba(0,0,0,0.6); */
    overflow: hidden;
  }
  .big-bg{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    background: url('~@/assets/image/bg-1.png') no-repeat;
    opacity: 0.9;
    background-size: 100% 100%;
    z-index: 10;
    pointer-events: none;
  }
  .select-month{
    position: absolute;
    top:66.8%;
    left:50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    font-size: 10px;
  }
  .month-date{
    width: 100px;
    cursor: pointer;
    color: #2c3d56;
    text-align: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 63px;
    text-align:center;
    position: relative;
    /* background-color: rgba(0,0,0,0.8); */
    padding: 0 10px;
    z-index: 10;
    pointer-events:visible;
  }
  .logo{
    width: 20%;
    text-align: left;
    color: #a9caf8;
  }
  .flex{
    font-family: ds-digitalnormal;
    width: 50%;
    display: flex;
    justify-content: space-around;
    height: 50%;
    color: #a9caf8;
  }
  .fix-right{
    width: 30%;
    font-family: ds-digitalnormal;
    color: #a4bbd8;
    font-size: 28px;
    line-height: 1.2em;
  }
  .fr{
    float: right;
  }
  .ball{
    font-family: ds-digitalnormal;
    font-size: 33px;
    width: 118px;
    height: 118px;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span{
      &:first-child{
        font-size: 15px;
      }
    }
  }
  .one{
    color: #49e4f2;
    background-image: url('~@/assets/image/lot/ball-1.png');
  }
  .two{
    color: #ff7a78;
    background-image: url('~@/assets/image/lot/ball-2.png');
  }
  .three{
    color: #ffa85b;
    background-image: url('~@/assets/image/lot/ball-3.png');
  }
  .lg-size{
    font-size: 40px;
  }
  .middle{
    width: 100%;
    height: calc(50% - 66.7px);
  }
  .bottom{
    width: 100%;
    height: calc(50% - 33.3px);;
  }
  .left,.right{
    /*position: relative;*/
    /*float: left;*/
    position: absolute;
    bottom: 0px;
    /*left: 16px;*/
    width: 100%;
    height: 20px;
    text-align: center;
    overflow: hidden;
    height: calc(50% - 63px);
    z-index: 1;
    padding: 0 10px;
  }
  .left{
    width: 50%;
    left: 0px;
    padding: 0;
    pointer-events: none;
  }
  .right{
    width: 50%;
    right: 10px;
    padding: 0;
    pointer-events: none;
  }
</style>
<style lang="scss">
  .lot-big-data {
    .middle-left,.middle-right{
      .amap-info-content{
        background-color: #060d15;
	      box-shadow: 0px 13px 32px 0px  rgba(0, 0, 0, 0.55);
      }
      .amap-info-sharp{
        border-right-color: rgba(0,0,0,0.55);
        border-left-color: rgba(0,0,0,0.55);
      }
      .map-title{
        color: #fff;
        line-height: 24px;
      }
      .map-hr{
        height: 2px;
        background-color: #2a394a;
        margin:5px 0;
      }
    }
    .ant-tabs-nav-container{padding:0 5px;}
    .ant-tabs-bar{border:none}
    .ant-tabs-tab{
      padding: 4px 8px!important;
      color: #fff;
      background-color: #072e57;
      margin-right: 6px!important;
    }
    .ant-tabs-tab-active{
      color: #fff;
      background-color: #0072ff;
    }
    .ant-table-wrapper{
      margin-top: 5px!important;
    }
    .box-container .box-container-inner,.table-row-single,.ant-table-placeholder{
      background-color: transparent;
    }
    .ant-pagination-item {
      background-color: #072e57;
      border-color: transparent;
      a{
      color: #fff;
      }
    }
    .ant-pagination-item-ellipsis{
      color: #1890ff!important;
    }
    .ant-pagination-item-link{
      background-color: #072e57;
      color: #fff;
      border-color: transparent;
    }
    .ant-tag-green,.ant-tag-red{
      background-color: rgba(0,114,255,0.8);
      border-color: transparent;
    }
  }
  .slef-pop{
    top: -10px !important;
    left: 475px !important;
    width:380px;
    height: 356px;
    .ant-popover-inner{
      background-color: #060d15;
      box-shadow: 0px 13px 32px 0px  rgba(0, 0, 0, 0.55);
    }
    .ant-popover-title{
      border:none;
    }
    .ant-popover-inner-content{
      padding-top:0;
    }
    .ant-popover-arrow{
      border-left-color: rgba(0, 0, 0, 0.55)!important;
      border-bottom-color: rgba(0, 0, 0, 0.55)!important
    }
    td,th{
      border: none !important;
      color: #90a5b6 !important;
    }
    th{
      background-color: #383838 !important;
    }
    .map-title{
      color: #fff;
      line-height: 24px;
    }
    .map-hr{
      height: 2px;
      background-color: #2a394a;
      margin:5px 0;
    }
  }
  .map-content{
    font-size: 14px;
  }
</style>