<template>
  <div class="middle-top">
    <div class="flex">
<!--      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" style="margin-left: 2%;width: 50%">-->
<!--        <a-form-model-item label="区域" prop="areacode" class="self-select" style="width: auto">-->
<!--          <a-cascader-->
<!--              @change="(val)=>handleChange(val,'areacode')"-->
<!--              :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }"-->
<!--              :allowClear="false"-->
<!--              popupClassName="self"-->
<!--              :options="areaOptions"-->
<!--              v-model="queryAreaSelected"-->
<!--              :load-data="areaOnLoadChildren"-->
<!--              placeholder="请选择区域"-->
<!--              :change-on-select="true"-->
<!--              style="width: 160px"-->
<!--          >-->
<!--          </a-cascader>-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" style="width: 100%;">
        <a-form-model-item label="公司/项目" prop="userdepid" class="self-select" style="margin-left: 5%;width: 22%">
          <a-cascader
              :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
              :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
              v-model="userdepidCascaderSelected"
              :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
              placeholder="请选择公司/项目"
              :change-on-select="true"
              :allowClear="false"
              popupClassName="self"
              style="width: 180px"
          >
          </a-cascader>
        </a-form-model-item>
        <a-form-model-item label="" prop="monitorpointnum" class="self-select" style="margin-left: 4%;width: 9%">
          <a-row style="">
            <a-col :span="32" class="middle-top-title"><a-button type="link" @click="gotoMonitorPage('monitorpoint')" style="font-size: 18px;color: #05bebf;font-weight: bold">项目数量：<span>{{mainData.monitorpointnum}}</span></a-button></a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="" prop="clientnum" class="self-select" style="margin-left: 0%;width: 10%">
          <a-row style="width: 100%">
            <a-col :span="32" class="middle-top-title"><a-button type="link" @click="gotoMonitorPage('monitorinfo')" style="font-size: 18px;color: #05bebf;font-weight: bold">监控端数量：<span>{{mainData.clientnum+mainData.devclientnum}}</span></a-button></a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="" prop="clientnum" class="self-select" style="margin-left: 0%;width: 10%">
          <a-row style="width: 100%">
            <a-col :span="32" class="middle-top-title"><a-button type="link" @click="gotoMonitorPage('offline')" style="font-size: 18px;color: #05bebf;font-weight: bold">离线监控端数量：<span style="color: red">{{mainData.offclientnum+mainData.offdevclientnum}}</span></a-button></a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="" prop="unhandlealarm" class="self-select" style="margin-left: 3.5%;width: 13%">
          <a-row style="width: 100%;">
            <a-col :span="32" class="middle-top-title"><a-button type="link" @click="gotoEventPage('alarm')" style="font-size: 18px;color: #05bebf;font-weight: bold">未处理报警数量：<span style="color: red">{{unhandleDate.unhandlealarm}}</span></a-button></a-col>
          </a-row>
        </a-form-model-item>
        <a-form-model-item label="" prop="unhandleevent" class="self-select" style="margin-left: 0%;width: 13%">
          <a-row style="width: 100%">
            <a-col :span="32" class="middle-top-title"><a-button type="link" @click="gotoEventPage('abnormal')" style="font-size: 18px;color: #05bebf;font-weight: bold">未处理异常数量：<span style="color: red">{{unhandleDate.unhandleevent}}</span></a-button></a-col>
          </a-row>
        </a-form-model-item>
      </a-form-model>
    </div>
    <monitor-point-list :visible.sync="modalVisible" :show-type="modalShowType" :detail-data="queryParams.userdepid"></monitor-point-list>
    <event-record-list :visible.sync="eventModalVisible" :show-type="eventModalShowType" :detail-data="queryParams.userdepid"></event-record-list>
  </div>
</template>
<script>
import {getCache, getItemFromArrayByKey, getTreeDeepString} from 'U'
import { getAreaByMonitor} from 'A/wlw'
import {getIotMaindata, getUnhandleEventrecord} from "A/monitoring";
import {mapState} from "vuex";
import deptselect from '@/mixins/deptselect'
import {geteChildListByParentcode, getParentTreeBycode} from "A/xtpz";
import MonitorPointList from "C/modals/MonitorPointList";
import EventRecordList from "C/modals/EventRecordList";
export default{
  name:"MiddleTop",
  mixins: [deptselect],
  components:{
    MonitorPointList,
    EventRecordList,
  },
  data() {
    return {
      modalVisible:false,
      modalShowType:'',
      eventModalVisible:false,
      eventModalShowType:'',
      userdepnum:10,
      clientnum:10,
      selectedArea:'',
      selectedPlace:'',
      selectedLiftyear:'',
      selectedDevicemodelid:'',
      placeOptions:[],
      terminaltypes:[],
      queryParams: {
        monitorpointname:'',
        userdepid:'',
      },
      monitorpointname:'',
      queryAreaSelected: [],
      areaOptions: [],
      areaCascaderSelected: [],
      userdepidCascaderSelected: [],
      mainData:{
        monitorpointnum:0,
        clientnum:0,
        offclientnum:0,
        devclientnum:0,
        offdevclientnum:0,
      },
      unhandleDate:{
        unhandlealarm:0,
        unhandleevent:0,
      },
      userdepid:'',
      timers:null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch:{
    'monitorpointname':function (){
      this.$emit('select-opt',"monitorpointname",this.monitorpointname)
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
        this.$emit('select-opt',"userdepid",val[val.length-1])
        this.getIotMain()
        this.getUnhandleEventNum()
      }else {
        this.queryParams.userdepid = '';
      }
    },
  },
  mounted(){
    let that = this;
    this.timers = setInterval(()=>{
      that.getIotMain();
      that.getUnhandleEventNum()
    },5*60*1000)
  },
  beforeDestroy(){
    clearInterval(this.timers)
    this.timers=null
  },
  created () {
    let logininfo = getCache('logininfo', true);
    if(logininfo) {
      this.userdepid = logininfo.userdepid;
    }
    this.getArea()
    this.initDeptOptionsAll();
    this.getIotMain();
    this.getUnhandleEventNum()
  },
  methods: {
    gotoMonitorPage(type){
      if(type=='monitorpoint'){
        this.modalShowType='monitorpoint'
        this.modalVisible=true
        // this.$router.push({
        //   name:'monitorfile'
        // })
      }else if(type=='monitorinfo'){
        this.modalShowType='monitorinfo'
        this.modalVisible=true
        // this.$router.push({
        //   name:'monitorList'
        // })
      }else if(type=='offline'){
        this.modalShowType='offline'
        this.modalVisible=true
        // this.$router.push({
        //   name:'monitorList'
        // })
      }
    },
    gotoEventPage(type){
      if(type=='alarm'){
        this.eventModalShowType='unhandleAlarm'
        this.eventModalVisible=true
        // this.$router.push({
        //   name:'unhandleAlarm'
        // })
      }else if(type=='abnormal'){
        this.eventModalShowType='unhandleAbnormal'
        this.eventModalVisible=true
        // this.$router.push({
        //   name:'unhandleAbnormal'
        // })
      }
    },
    getIotMain(){//物联网大数据主视图
      let params={
        areacode: this.selectedArea=="0"?"":this.selectedArea,
        userdepsid:this.queryParams.userdepid?this.queryParams.userdepid:this.userdepid,
      }
      getIotMaindata(params).then(res=>{
        if(res&&res.returncode==="0"){
          this.mainData = res.item
        }
      })
    },
    getUnhandleEventNum(){
      let params={
        userdepsid:this.queryParams.userdepid,
        batterycar:true,
      }
      getUnhandleEventrecord(params).then(res=>{
        if(res&&res.returncode==="0"){
          this.unhandleDate=res.item[0]
        }
      })
    },
    handleChange(val,type){
      if(type=="areacode"){
        this.selectedArea=val[val.length-1];
      }else if(type=="placeid"){
        this.selectedPlace=val;
      }
      this.getIotMain()
      if(type==='areacode'){
        this.$emit('select-opt',type,val[val.length-1])
      }else{
        this.$emit('select-opt',type,val)
      }
    },
    getArea(){
      getAreaByMonitor({parentcode:''}).then(res=>{
        if(res && res.returncode == '0') {
          const result = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [{areacode:"0",areaname:'全部',isLeaf:true}].concat(result)
        }
      })
    },
    areaOnLoadChildren(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      this.getChildArea(targetOption.areacode);
    },
    getChildArea(parentcode) {
      let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
      let params = {
        parentcode
      }
      geteChildListByParentcode(params).then(res => {
        targetOption.loading = false;
        if(res && res.returncode == '0') {
          targetOption.children = res.item.map(item => {
            item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
            return item;
          });
          this.areaOptions = [...this.areaOptions]
        }
      }).catch(err => {
        targetOption.loading = false;
      })
    },
    initAreaOptionsByAreacode(areacode) {
      let params = {
        areacode
      }
      getParentTreeBycode(params).then(res => {
        if(res && res.returncode == '0') {
          let areacodeTreeStr = getTreeDeepString(res.item, '', 'areacode', 'childArea');
          let deepAreaList = areacodeTreeStr.split('|');
          if(deepAreaList.length > 1) {
            this.initAreaListTree(deepAreaList, 0);
          }else {
            this.areaCascaderSelected = deepAreaList;
          }
        }
      })
    },
    initAreaListTree(deepAreaList, index) {
      if(index < deepAreaList.length-1) {
        let parentcode = deepAreaList[index];
        let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
        let params = {
          parentcode
        }
        geteChildListByParentcode(params).then(res => {
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            this.initAreaListTree(deepAreaList, index+1)
          }
        })
      }else {
        this.areaCascaderSelected = deepAreaList;
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  .middle-top{
    width: 100%;
    height: 70%;
  }
  .middle-top-title{
    color: #00d1ce;
    font-size: 18px;
    font-weight: 700;
    & >span{
      font-family: ds-digitalnormal;
      font-size: 22px;
    }
  }
  .flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9adeff;
    font-size: 16px;
    pointer-events: auto;
  }
  .tab-size{
    pointer-events: auto;
  }
  .tab-size span{
    display: inline-block;
    background-color: rgba(1,37,77,.7);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    padding: 5px 14px;
    &.active{
      background-color: #0060d5;
    }
  }
</style>
<style lang="scss">
  .self-select{
    .ant-select{
      width:120px;
      color: #90a5b6;
    }
    .anticon svg{
      color: #90a5b6;
    }
    .ant-select-selection,.ant-select-selection__clear,.ant-cascader-picker{
      background-color: transparent;
    }
    .ant-form-item-label > label,.ant-cascader-picker-label{
      color: #90a5b6!important;
    }
  }
  .self.ant-select-dropdown{
    background-color: transparent;
    .ant-select-dropdown-menu-item{
      color: #90a5b6;
    }
  }
  .self.ant-cascader-menus{
    background-color: transparent;
    color: #90a5b6;
  }
</style>