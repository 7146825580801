<template>
  <a-modal v-model="modalVisible" title="时间运行图" :width="1250" :centered="true" @cancel="closeModal">
    <template slot="footer">
      <a-button type="primary" @click="closeModal()">关闭</a-button>
    </template>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" :label-col="{span:14}" :wrapper-col="{span:10}">
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 35%;margin-left: -9%">
              <a-range-picker v-model="queryInstallDate" style="width: 300px;"
                              format="YYYY-MM-DD HH:mm:ss"
                              :show-time="{ format: 'HH:mm:ss' }"
                              :placeholder="['开始时间', '结束时间']">
                <template slot="renderExtraFooter">
                  <a-button :type="buttonType" @click="onlyDate">只选日期</a-button>
                </template>
              </a-range-picker>
            </a-form-model-item>
            <span style="font-weight: bold;font-size:15px;color: red;margin-left: 90px;">图表中若无数据,请确认查询时间区间选择是否正确(默认是最近24小时的数据)</span>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="table-header">
          <div class="table-btns">
            <span style="color: red;">点击左侧图标可以选择展示哪些曲线</span>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
          <div v-if="ChartType==='transformer_1'" class="line-shape" id="line1" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='transformer_2'" class="line-shape" id="line2" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='electricity_meter_1'" class="line-shape" id="line3" style="height: 400px;width: 100%"></div>
          <div v-if="ChartType==='electricity_meter_1'" class="line-shape" id="line4" style="height: 400px;width: 100%"></div>
          <div v-if="ChartType==='electricity_meter_2'" class="line-shape" id="line5" style="height: 400px;width: 100%"></div>
          <div v-if="ChartType==='electricity_meter_2'" class="line-shape" id="line6" style="height: 400px;width: 100%"></div>
          <div v-if="ChartType==='feeder_circuit_1'" class="line-shape" id="line7" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='feeder_circuit_2'" class="line-shape" id="line8" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='electric_room_temperature_humidity'" class="line-shape" id="line9" style="height: 600px;width: 100%"></div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import * as echarts from 'echarts';
import {getDateTimeRange, getTimeRange} from "U/index";
import {getSceneRunTimeCharts} from "A/monitoring";
import {getSceneInfoListByCondition} from "A/patrol";
export default {
  name: "StatusData",
  components: {},
  props: {
    visible: {
      default: false
    },
    ChartType: {
      default: null
    }
  },
  data() {
    return {
      moment,
      buttonType:'',
      modalVisible: false,
      queryInstallDate: null,
      queryParams: {
        starttime: '',
        endtime: '',
      },
      DataList1: [],
      DataList2: [],
      DataList3: [],
      DataList4: [],
      DataList5: [],
      yInterval: 1,
      feeder_circuit_alarm:900,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "时间运行图"
    },
    breadcrumb() {
      const pages = [{name: "场景管理", path: ""}]
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.getConfiguration()
        this.getTableData()
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getDateTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {},
  methods: {
    onlyDate(){
      if(!this.queryInstallDate){
        this.$message.error("请先选择日期")
      }else{
        this.buttonType='primary'
        this.queryInstallDate[0]._d=new Date(moment(this.queryInstallDate[0]._d).format("YYYY-MM-DD")+" 00:00:00")
        this.queryInstallDate[1]._d=new Date(moment(this.queryInstallDate[1]._d).format("YYYY-MM-DD")+" 23:59:59")
        let {start, end} = getDateTimeRange(this.queryInstallDate);
        this.queryParams.starttime = start;
        this.queryParams.endtime = end;
      }
    },
    closeModal() {
      this.modalVisible = false
      this.resetQueryParams()
    },
    getConfiguration(){
      getSceneInfoListByCondition({scenetemplateid:this.$store.state.scenetemplateid,sceneinfotype:4}).then(res=>{
        if (res && res.returncode === '0') {
          this.feeder_circuit_alarm=res.item[0].configuration1
        }
      })
    },
    getTableData() {
      this.DataList1 = []
      this.DataList2 = []
      this.DataList3 = []
      this.DataList4 = []
      this.DataList5 = []
      this.tableLoading = true;
      if (this.queryParams.starttime === '') {
        this.queryParams.starttime = moment((new Date().getTime() - 86400000)).format("YYYYMMDDHHmmss")
      }
      if (this.queryParams.endtime === '') {
        this.queryParams.endtime = moment(new Date()).format("YYYYMMDDHHmmss")
      }
      let params = {
        ...this.queryParams,
        scenetemplateid:this.$store.state.scenetemplateid,
        type: this.ChartType,
      };
      getSceneRunTimeCharts(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          if (this.ChartType === "transformer_1" || this.ChartType === "transformer_2") {
            if (res.data1.length > 0 && res.data1[0].ydata === "0") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[0].ydata === "1") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 1])
            } else {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            }
            for (let i = 0; i < res.data1.length; i++) {
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
            }
            if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "1") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "0") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 1])
            }
            if (res.data2.length > 0 && res.data2[0].ydata === "0") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[0].ydata === "1") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 3])
            } else {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            }
            for (let i = 0; i < res.data2.length; i++) {
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata == 0 ? 2 : 3])
            }
            if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "1") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "0") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 3])
            }
            for(let i=0;i<res.data3.length;i++){
              this.DataList3.push([moment(res.data3[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data3[i].ydata])
            }
            let that = this
            if (this.ChartType === "transformer_1") {
              setTimeout(() => {
                that.initCharts1()
              }, 500)
            } else if (this.ChartType === "transformer_2") {
              setTimeout(() => {
                that.initCharts2()
              }, 500)
            }
          } else if (this.ChartType === "electricity_meter_1"||this.ChartType === 'electricity_meter_2') {
            for(let i=0;i<res.data1.length;i++){
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata])
              this.DataList3.push([moment(res.data3[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data3[i].ydata])
              this.DataList4.push([moment(res.data4[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data4[i].ydata])
              this.DataList5.push([moment(res.data5[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data5[i].ydata])
            }
            let that = this
            if (this.ChartType === "electricity_meter_1") {
              setTimeout(() => {
                that.initCharts3()
                that.initCharts4()
              }, 500)
            } else if (this.ChartType === "electricity_meter_2") {
              setTimeout(() => {
                that.initCharts5()
                that.initCharts6()
              }, 500)
            }
          } else if (this.ChartType === "feeder_circuit_1"||this.ChartType === "feeder_circuit_2") {
            for(let i=0;i<res.data1.length;i++){
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata])
              this.DataList3.push([moment(res.data3[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data3[i].ydata])
            }
            let that = this
            if (this.ChartType === "feeder_circuit_1") {
              setTimeout(() => {
                that.initCharts7()
              }, 500)
            } else if (this.ChartType === "feeder_circuit_2") {
              setTimeout(() => {
                that.initCharts8()
              }, 500)
            }
          }else if(this.ChartType ==='electric_room_temperature_humidity'){
            for(let i=0;i<res.data1.length;i++){
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata])
            }
            let that = this
            setTimeout(() => {
              that.initCharts9()
            }, 500)
          }
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.buttonType=''
      this.DataList1 = []
      this.DataList2 = []
      this.DataList3 = []
      this.DataList4 = []
      this.DataList5 = []
    },
    //1#变压器
    initCharts1() {
      let chartDom = document.getElementById('line1');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "1#变压器时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            if(params[0].seriesName==='负荷率'){
              return params[0].axisValueLabel + "<br/>" +params[0].seriesName+"："+((params[0].data[1]-4)*100).toFixed(1)+"%"
            }else{
              return params[0].axisValueLabel + "<br/>" +params[0].seriesName+"："+params[0].data[1]
            }
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '1#变压器',
            min: 0,
            max: 5,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '风机运行'
                } else if (value === 3) {
                  return '变压器超温'
                } else if (value === 5) {
                  return '负荷率'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '风机运行',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '变压器超温',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '负荷率',
            type: 'line',
            data: this.DataList3,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return ((params.value[1]-4)*100).toFixed(1)+"%"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //2#变压器
    initCharts2() {
      let chartDom = document.getElementById('line2');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "2#变压器时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            if(params[0].seriesName==='负荷率'){
              return params[0].axisValueLabel + "<br/>" +params[0].seriesName+"："+((params[0].data[1]-4)*100).toFixed(1)+"%"
            }else{
              return params[0].axisValueLabel + "<br/>" +params[0].seriesName+"："+params[0].data[1]
            }
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '变压器',
            min: 0,
            max: 5,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '风机运行'
                } else if (value === 3) {
                  return '变压器超温'
                } else if (value === 5) {
                  return '负荷率'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '风机运行',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '变压器超温',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '负荷率',
            type: 'line',
            data: this.DataList3,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return ((params.value[1]-4)*100).toFixed(1)+"%"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //1#电量表-电流
    initCharts3() {
      let chartDom = document.getElementById('line3');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "电流时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return params[0].axisValueLabel
                + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"A"):"")
                + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"A"):"")
                + (params.length>=3?("<br/>" +params[2].seriesName+"："+params[2].data[1]+"A"):"")
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电流(A)',
            // min:200,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'Ic(A)',
            type: 'line',
            data: this.DataList5,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ib(A)',
            type: 'line',
            data: this.DataList4,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ia(A)',
            type: 'line',
            data: this.DataList3,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //1#电量表-电压及功率
    initCharts4() {
      let chartDom = document.getElementById('line4');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "电压及功率时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            if(params[0].seriesName==='Uab(V)') {
              return params[0].axisValueLabel
                  +"<br/>" +params[0].seriesName+"："+params[0].data[1]+"V"
                  +"<br/>" +params[1].seriesName+"："+params[1].data[1]+"KVA"
            }else{
              return params[0].axisValueLabel
                  +"<br/>" +params[0].seriesName+"："+params[0].data[1]+"KVA"
                  +"<br/>" +params[1].seriesName+"："+params[1].data[1]+"V"
            }
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电压(V)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
          {
            type: 'value',
            name: '功率(KVA)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'P(KVA)',
            type: 'line',
            yAxisIndex:1,
            data: this.DataList2,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"KVA"
            //     }
            //   }
            // },
          },
          {
            name: 'Uab(V)',
            type: 'line',
            yAxisIndex:0,
            data: this.DataList1,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"V"
            //     }
            //   }
            // },
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: '380V',
                  yAxis: 380
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            }
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //2#电量表-电流
    initCharts5() {
      let chartDom = document.getElementById('line5');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "电流时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return params[0].axisValueLabel
                + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"A"):"")
                + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"A"):"")
                + (params.length>=3?("<br/>" +params[2].seriesName+"："+params[2].data[1]+"A"):"")
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电流(A)',
            // min:200,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'Ic(A)',
            type: 'line',
            data: this.DataList5,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ib(A)',
            type: 'line',
            data: this.DataList4,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ia(A)',
            type: 'line',
            data: this.DataList3,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //2#电量表-电压及功率
    initCharts6() {
      let chartDom = document.getElementById('line6');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "电压及功率时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            if(params[0].seriesName==='Uab(V)'){
              return params[0].axisValueLabel
                  + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"V"):"")
                  + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"KVA"):"")
            }else{
              return params[0].axisValueLabel
                  + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"KVA"):"")
                  + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"V"):"")
            }
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电压(V)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
          {
            type: 'value',
            name: '功率(KVA)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'P(KVA)',
            type: 'line',
            yAxisIndex:1,
            data: this.DataList2,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"KVA"
            //     }
            //   }
            // },
          },
          {
            name: 'Uab(V)',
            type: 'line',
            yAxisIndex:0,
            data: this.DataList1,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: '380V',
                  yAxis: 380
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"V"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //1#馈电回路
    initCharts7() {
      let chartDom = document.getElementById('line7');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "1#馈电回路时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return params[0].axisValueLabel
                + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"A"):"")
                + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"A"):"")
                + (params.length>=3?("<br/>" +params[2].seriesName+"："+params[2].data[1]+"A"):"")
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电流(A)',
            // min: 400,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'Ic',
            type: 'line',
            data: this.DataList3,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ib',
            type: 'line',
            data: this.DataList2,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ia',
            type: 'line',
            data: this.DataList1,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //2#馈电回路
    initCharts8() {
      let chartDom = document.getElementById('line8');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "2#馈电回路时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return params[0].axisValueLabel
                + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"A"):"")
                + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"A"):"")
                + (params.length>=3?("<br/>" +params[2].seriesName+"："+params[2].data[1]+"A"):"")
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '电流(A)',
            // min: 400,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
            },
          },
        ],
        series: [
          {
            name: 'Ic',
            type: 'line',
            data: this.DataList3,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ib',
            type: 'line',
            data: this.DataList2,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
          {
            name: 'Ia',
            type: 'line',
            data: this.DataList1,
            markLine: {
              symbol: ['none', 'none'], // 去掉箭头
              label: {
                show: true,
                position: 'start',
                formatter: '{b}',
                textStyle: {
                  color: 'red',
                  fontSize: 14
                },
              },
              data: [
                {
                  name: this.feeder_circuit_alarm+"A",
                  yAxis: this.feeder_circuit_alarm
                }
              ],
              lineStyle: {
                color: '#f00'
              }
            },
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"A"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //温湿度
    initCharts9() {
      let chartDom = document.getElementById('line9');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "温湿度时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            if(params[0].seriesName==='湿度'){
              return params[0].axisValueLabel
                  + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"%"):"")
                  + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"℃"):"")
            }else{
              return params[0].axisValueLabel
                  + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"℃"):"")
                  + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"%"):"")
            }
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '温度(℃)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: '{value} ℃'
            },
          },
          {
            type: 'value',
            name: '湿度(%)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: '{value} %'
            },
          },
        ],
        series: [
          {
            name: '湿度',
            type: 'line',
            yAxisIndex:1,
            data: this.DataList2,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"%"
            //     }
            //   }
            // },
          },
          {
            name: '温度',
            type: 'line',
            yAxisIndex:0,
            data: this.DataList1,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"℃"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
  },
}
</script>
<style lang="scss" scoped>
.line-shape{
  margin-bottom: 0px;
  pointer-events: auto;
}
</style>