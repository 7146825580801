<template>
  <div @click="onClick" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <component
        :is="config.component"
        v-if="config.component.startsWith('SVG')"
        ref="component"
        class="component"
        :style="getSVGStyle(config.style)"
        :prop-value="config.propValue"
        :element="config"
        :request="config.request"
        :linkage="config.linkage"
    />
    <component
        :is="config.component"
        v-else
        ref="component"
        class="component"
        :style="getStyle(config.style)"
        :prop-value="config.propValue"
        :element="config"
        :request="config.request"
        :linkage="config.linkage"
    />
    <water-system-status-data :visible.sync="waterSystemStatusVisible"></water-system-status-data>
    <water-system-sequence-chart :visible.sync="waterSystemChartVisible" :ChartType="waterSystemChartType"></water-system-sequence-chart>
    <electricity-system-status-data :visible.sync="electricitySystemStatusVisible"></electricity-system-status-data>
    <electricity-system-sequence-chart :visible.sync="electricitySystemChartVisible" :ChartType="electricitySystemChartType"></electricity-system-sequence-chart>
  </div>
</template>
<script>
import { getStyle, getSVGStyle } from '@/utils/style';
import runAnimation from '@/utils/runAnimation';
import { mixins } from '@/utils/events';
import eventBus from '@/utils/eventBus';
import {getSceneInfoListByCondition} from "A/patrol";
import WaterSystemStatusData from "V/SceneManagement/waterSystem/WaterSystemStatusData";
import WaterSystemSequenceChart from "V/SceneManagement/waterSystem/WaterSystemSequenceChart";
import ElectricitySystemStatusData from "V/SceneManagement/electricitySystem/ElectricitySystemStatusData";
import ElectricitySystemSequenceChart from "V/SceneManagement/electricitySystem/ElectricitySystemSequenceChart";
export default {
  mixins: [mixins],
  components: {
    WaterSystemStatusData,
    WaterSystemSequenceChart,
    ElectricitySystemStatusData,
    ElectricitySystemSequenceChart,
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    sceneSystemData:{
      type: Object,
      default: () => {
      },
    },
    iotConfigs:{
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      waterSystemStatusVisible:false,
      waterSystemChartVisible:false,
      waterSystemChartType:'',
      electricitySystemStatusVisible:false,
      electricitySystemChartVisible:false,
      electricitySystemChartType:'',
      picVisible:false,
      picurl:"",
      baseurl:"https://fda.u-lake.com/",
    }
  },
  mounted() {
    runAnimation(this.$refs.component.$el, this.config.animations)
  },
  watch:{
    //水系统场景
    'sceneSystemData.Tank_Volume':function (){
      if(this.sceneSystemData.Tank_Volume){
        this.onCurrentData(this.sceneSystemData.Tank_Volume,"Tank_Volume")
      }
    },
    'sceneSystemData.High_Water_Pressure':function (){
      if(this.sceneSystemData.High_Water_Pressure){
        this.onCurrentData(this.sceneSystemData.High_Water_Pressure,"High_Water_Pressure")
      }
    },
    'sceneSystemData.Low_Water_Pressure':function (){
      if(this.sceneSystemData.Low_Water_Pressure){
        this.onCurrentData(this.sceneSystemData.Low_Water_Pressure,"Low_Water_Pressure")
      }
    },
    'sceneSystemData.Celsius_Temperature':function (){
      this.onCurrentData(this.sceneSystemData.Celsius_Temperature,"Celsius_Temperature")
      if(this.sceneSystemData.Celsius_Temperature>=40||this.sceneSystemData.Celsius_Temperature<=5){
        this.onDateChange(this.sceneSystemData.Celsius_Temperature,"Celsius_Temperature")
      }else{
        this.onDateRecover(this.sceneSystemData.Celsius_Temperature,"Celsius_Temperature")
      }
    },
    'sceneSystemData.Relative_Humidity':function (){
      this.onCurrentData(this.sceneSystemData.Relative_Humidity,"Relative_Humidity")
      if(this.sceneSystemData.Relative_Humidity>=90){
        this.onDateChange(this.sceneSystemData.Relative_Humidity,"Relative_Humidity")
      }else{
        this.onDateRecover(this.sceneSystemData.Relative_Humidity,"Relative_Humidity")
      }
    },
    'sceneSystemData.fmt_time':function (){
      if(this.sceneSystemData.fmt_time){
        this.onCurrentData(this.sceneSystemData.fmt_time,"fmt_time")
      }
    },
    'sceneSystemData.WaterPumpHouse_Person_Status':function (){
      if(this.sceneSystemData.WaterPumpHouse_Person_Status==1){
        this.onDateChange(this.sceneSystemData.WaterPumpHouse_Person_Status,"WaterPumpHouse_Person_Status")
        this.onCurrentData("人员状态：有人","waterpumphouse_person_status")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpHouse_Person_Status,"WaterPumpHouse_Person_Status")
        this.onCurrentData("人员状态：无人","waterpumphouse_person_status")
      }
    },
    'sceneSystemData.EngineRoom_DoorState':function (){
      if(this.sceneSystemData.EngineRoom_DoorState==1){
        this.onDateChange(this.sceneSystemData.EngineRoom_DoorState,"EngineRoom_DoorState")
        this.onCurrentData("机房门状态("+this.iotConfigs.EngineRoom_Door_Open_Bind_Port+")：打开","engineroom_doorstate")
      }else{
        this.onDateRecover(this.sceneSystemData.EngineRoom_DoorState,"EngineRoom_DoorState")
        this.onCurrentData("机房门状态("+this.iotConfigs.EngineRoom_Door_Open_Bind_Port+")：关闭","engineroom_doorstate")
      }
    },
    'sceneSystemData.EngineRoom_OnFire':function (){
      if(this.sceneSystemData.EngineRoom_OnFire==1){
        this.onDateChange(this.sceneSystemData.EngineRoom_OnFire,"EngineRoom_OnFire")
        this.onCurrentData("机房跑水("+this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port+")：是","engineroom_onfire")
      }else{
        this.onDateRecover(this.sceneSystemData.EngineRoom_OnFire,"EngineRoom_OnFire")
        this.onCurrentData("机房跑水("+this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port+")：否","engineroom_onfire")
      }
    },
    'sceneSystemData.EngineRoom_FeedFanState':function (){
      if(this.sceneSystemData.EngineRoom_FeedFanState==1){
        this.onDateChange(this.sceneSystemData.EngineRoom_FeedFanState,"EngineRoom_FeedFanState")
        this.onCurrentData("送风机状态("+this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port+")：运行","engineroom_feedfanstate")
      }else{
        this.onDateRecover(this.sceneSystemData.EngineRoom_FeedFanState,"EngineRoom_FeedFanState")
        this.onCurrentData("送风机状态("+this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port+")：停止","engineroom_feedfanstate")
      }
    },
    'sceneSystemData.EngineRoom_ExhaustFanState':function (){
      if(this.sceneSystemData.EngineRoom_ExhaustFanState==1){
        this.onDateChange(this.sceneSystemData.EngineRoom_ExhaustFanState,"EngineRoom_ExhaustFanState")
        this.onCurrentData("排风机状态("+this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port+")：运行","engineroom_exhaustfanstate")
      }else{
        this.onDateRecover(this.sceneSystemData.EngineRoom_ExhaustFanState,"EngineRoom_ExhaustFanState")
        this.onCurrentData("排风机状态("+this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port+")：停止","engineroom_exhaustfanstate")
      }
    },
    'sceneSystemData.SubmersiblePump_Power':function (){
      if(this.sceneSystemData.SubmersiblePump_Power==1){
        this.onDateChange(this.sceneSystemData.SubmersiblePump_Power,"SubmersiblePump_Power")
        this.onCurrentData("潜水泵电源状态("+this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port+")：故障","submersiblepump_power")
      }else{
        this.onDateRecover(this.sceneSystemData.SubmersiblePump_Power,"SubmersiblePump_Power")
        this.onCurrentData("潜水泵电源状态("+this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port+")：正常","submersiblepump_power")
      }
    },
    'sceneSystemData.SubmersiblePump_1_State':function (){
      if(this.sceneSystemData.SubmersiblePump_1_State==1){
        this.onDateChange(this.sceneSystemData.SubmersiblePump_1_State,"SubmersiblePump_1_State")
        this.onCurrentData("潜水泵1运行状态("+this.iotConfigs.SubmersiblePump_1_Start_Bind_Port+")：运行","submersiblepump_1_state")
      }else{
        this.onDateRecover(this.sceneSystemData.SubmersiblePump_1_State,"SubmersiblePump_1_State")
        this.onCurrentData("潜水泵1运行状态("+this.iotConfigs.SubmersiblePump_1_Start_Bind_Port+")：停止","submersiblepump_1_state")
      }
    },
    'sceneSystemData.SubmersiblePump_1_Fault':function (){
      if(this.sceneSystemData.SubmersiblePump_1_Fault==1){
        this.onDateChange(this.sceneSystemData.SubmersiblePump_1_Fault,"SubmersiblePump_1_Fault")
        this.onCurrentData("潜水泵1故障("+this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port+")：有","submersiblepump_1_fault")
      }else{
        this.onDateRecover(this.sceneSystemData.SubmersiblePump_1_Fault,"SubmersiblePump_1_Fault")
        this.onCurrentData("潜水泵1故障("+this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port+")：无","submersiblepump_1_fault")
      }
    },
    'sceneSystemData.SubmersiblePump_2_State':function (){
      if(this.sceneSystemData.SubmersiblePump_2_State==1){
        this.onDateChange(this.sceneSystemData.SubmersiblePump_2_State,"SubmersiblePump_2_State")
        this.onCurrentData("潜水泵2运行状态("+this.iotConfigs.SubmersiblePump_2_Start_Bind_Port+")：运行","submersiblepump_2_state")
      }else{
        this.onDateRecover(this.sceneSystemData.SubmersiblePump_2_State,"SubmersiblePump_2_State")
        this.onCurrentData("潜水泵2运行状态("+this.iotConfigs.SubmersiblePump_2_Start_Bind_Port+")：停止","submersiblepump_2_state")
      }
    },
    'sceneSystemData.SubmersiblePump_2_Fault':function (){
      if(this.sceneSystemData.SubmersiblePump_2_Fault==1){
        this.onDateChange(this.sceneSystemData.SubmersiblePump_2_Fault,"SubmersiblePump_2_Fault")
        this.onCurrentData("潜水泵2故障("+this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port+")：有","submersiblepump_2_fault")
      }else{
        this.onDateRecover(this.sceneSystemData.SubmersiblePump_2_Fault,"SubmersiblePump_2_Fault")
        this.onCurrentData("潜水泵2故障("+this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port+")：无","submersiblepump_2_fault")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_Fault':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_Fault==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_Fault,"WaterPumpGroup_1_Fault")
        this.onCurrentData("水泵组1故障("+this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port+")：有","waterpumpgroup_1_fault")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_Fault,"WaterPumpGroup_1_Fault")
        this.onCurrentData("水泵组1故障("+this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port+")：无","waterpumpgroup_1_fault")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_PipeNetworkOverpressure':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkOverpressure==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkOverpressure,"WaterPumpGroup_1_PipeNetworkOverpressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port+")：超压","waterpumpgroup_1_pipenetworkoverpressure")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkOverpressure,"WaterPumpGroup_1_PipeNetworkOverpressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port+")：正常","waterpumpgroup_1_pipenetworkoverpressure")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_PipeNetworkLosspressure':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkLosspressure==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkLosspressure,"WaterPumpGroup_1_PipeNetworkLosspressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port+")：失压","waterpumpgroup_1_pipenetworklosspressure")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_PipeNetworkLosspressure,"WaterPumpGroup_1_PipeNetworkLosspressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port+")：正常","waterpumpgroup_1_pipenetworklosspressure")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_FirstMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_FirstMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_FirstMainPumpState,"WaterPumpGroup_1_FirstMainPumpState")
        this.onCurrentData("主泵1运行状态("+this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port+")：运行","waterpumpgroup_1_firstmainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_FirstMainPumpState,"WaterPumpGroup_1_FirstMainPumpState")
        this.onCurrentData("主泵1运行状态("+this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port+")：停止","waterpumpgroup_1_firstmainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_SecondMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_SecondMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_SecondMainPumpState,"WaterPumpGroup_1_SecondMainPumpState")
        this.onCurrentData("主泵2运行状态("+this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port+")：运行","waterpumpgroup_1_secondmainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_SecondMainPumpState,"WaterPumpGroup_1_SecondMainPumpState")
        this.onCurrentData("主泵2运行状态("+this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port+")：停止","waterpumpgroup_1_secondmainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_ThreeMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_ThreeMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_ThreeMainPumpState,"WaterPumpGroup_1_ThreeMainPumpState")
        this.onCurrentData("主泵3运行状态("+this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port+")：运行","waterpumpgroup_1_threemainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_ThreeMainPumpState,"WaterPumpGroup_1_ThreeMainPumpState")
        this.onCurrentData("主泵3运行状态("+this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port+")：停止","waterpumpgroup_1_threemainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_1_PressureStabilizingPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_1_PressureStabilizingPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_1_PressureStabilizingPumpState,"WaterPumpGroup_1_PressureStabilizingPumpState")
        this.onCurrentData("稳压泵运行状态("+this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port+")：运行","waterpumpgroup_1_pressurestabilizingpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_1_PressureStabilizingPumpState,"WaterPumpGroup_1_PressureStabilizingPumpState")
        this.onCurrentData("稳压泵运行状态("+this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port+")：停止","waterpumpgroup_1_pressurestabilizingpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_Fault':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_Fault==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_Fault,"WaterPumpGroup_2_Fault")
        this.onCurrentData("水泵组2故障("+this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port+")：有","waterpumpgroup_2_fault")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_Fault,"WaterPumpGroup_2_Fault")
        this.onCurrentData("水泵组2故障("+this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port+")：无","waterpumpgroup_2_fault")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_PipeNetworkOverpressure':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkOverpressure==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkOverpressure,"WaterPumpGroup_2_PipeNetworkOverpressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port+")：超压","waterpumpgroup_2_pipenetworkoverpressure")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkOverpressure,"WaterPumpGroup_2_PipeNetworkOverpressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port+")：正常","waterpumpgroup_2_pipenetworkoverpressure")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_PipeNetworkLosspressure':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkLosspressure==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkLosspressure,"WaterPumpGroup_2_PipeNetworkLosspressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port+")：失压","waterpumpgroup_2_pipenetworklosspressure")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_PipeNetworkLosspressure,"WaterPumpGroup_2_PipeNetworkLosspressure")
        this.onCurrentData("管网状态("+this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port+")：正常","waterpumpgroup_2_pipenetworklosspressure")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_FirstMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_FirstMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_FirstMainPumpState,"WaterPumpGroup_2_FirstMainPumpState")
        this.onCurrentData("主泵1运行状态("+this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port+")：运行","waterpumpgroup_2_firstmainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_FirstMainPumpState,"WaterPumpGroup_2_FirstMainPumpState")
        this.onCurrentData("主泵1运行状态("+this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port+")：停止","waterpumpgroup_2_firstmainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_SecondMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_SecondMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_SecondMainPumpState,"WaterPumpGroup_2_SecondMainPumpState")
        this.onCurrentData("主泵2运行状态("+this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port+")：运行","waterpumpgroup_2_secondmainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_SecondMainPumpState,"WaterPumpGroup_2_SecondMainPumpState")
        this.onCurrentData("主泵2运行状态("+this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port+")：停止","waterpumpgroup_2_secondmainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_ThreeMainPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_ThreeMainPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_ThreeMainPumpState,"WaterPumpGroup_2_ThreeMainPumpState")
        this.onCurrentData("主泵3运行状态("+this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port+")：运行","waterpumpgroup_2_threemainpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_ThreeMainPumpState,"WaterPumpGroup_2_ThreeMainPumpState")
        this.onCurrentData("主泵3运行状态("+this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port+")：停止","waterpumpgroup_2_threemainpumpstate")
      }
    },
    'sceneSystemData.WaterPumpGroup_2_PressureStabilizingPumpState':function (){
      if(this.sceneSystemData.WaterPumpGroup_2_PressureStabilizingPumpState==1){
        this.onDateChange(this.sceneSystemData.WaterPumpGroup_2_PressureStabilizingPumpState,"WaterPumpGroup_2_PressureStabilizingPumpState")
        this.onCurrentData("稳压泵运行状态("+this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port+")：运行","waterpumpgroup_2_pressurestabilizingpumpstate")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpGroup_2_PressureStabilizingPumpState,"WaterPumpGroup_2_PressureStabilizingPumpState")
        this.onCurrentData("稳压泵运行状态("+this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port+")：停止","waterpumpgroup_2_pressurestabilizingpumpstate")
      }
    },
    'sceneSystemData.Radiator_CapState':function (){
      if(this.sceneSystemData.Radiator_CapState==1){
        this.onDateChange(this.sceneSystemData.Radiator_CapState,"Radiator_CapState")
        this.onCurrentData("水箱盖闭合状态("+this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port+")：打开","radiator_capstate")
      }else{
        this.onDateRecover(this.sceneSystemData.Radiator_CapState,"Radiator_CapState")
        this.onCurrentData("水箱盖闭合状态("+this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port+")：闭合","radiator_capstate")
      }
    },
    'sceneSystemData.Radiator_Overflow_Lack':function (){
      if(this.sceneSystemData.Radiator_Overflow_Lack==1){
        this.onDateChange(this.sceneSystemData.Radiator_Overflow_Lack,"Radiator_Overflow_Lack")
      }else{
        this.onDateRecover(this.sceneSystemData.Radiator_Overflow_Lack,"Radiator_Overflow_Lack")
      }
    },
    'sceneSystemData.Radiator_SupplyState':function (){
      if(this.sceneSystemData.Radiator_SupplyState==1){
        this.onDateChange(this.sceneSystemData.Radiator_SupplyState,"Radiator_SupplyState")
        this.onCurrentData("是否补水("+this.iotConfigs.Radiator_Supply_Start_Bind_Port+")：是","Radiator_SupplyState")
      }else{
        this.onDateRecover(this.sceneSystemData.Radiator_SupplyState,"Radiator_SupplyState")
        this.onCurrentData("是否补水("+this.iotConfigs.Radiator_Supply_Start_Bind_Port+")：否","Radiator_SupplyState")
      }
    },
    'sceneSystemData.Radiator_OverflowState':function (){
      if(this.sceneSystemData.Radiator_OverflowState==1){
        this.onDateChange(this.sceneSystemData.Radiator_OverflowState,"Radiator_OverflowState")
        this.onCurrentData("是否溢水("+this.iotConfigs.Radiator_Overflow_Start_Bind_Port+")：是","Radiator_OverflowState")
      }else{
        this.onDateRecover(this.sceneSystemData.Radiator_OverflowState,"Radiator_OverflowState")
        this.onCurrentData("是否溢水("+this.iotConfigs.Radiator_Overflow_Start_Bind_Port+")：否","Radiator_OverflowState")
      }
    },
    'sceneSystemData.Radiator_LackState':function (){
      if(this.sceneSystemData.Radiator_LackState==1){
        this.onDateChange(this.sceneSystemData.Radiator_LackState,"Radiator_LackState")
        this.onCurrentData("是否缺水("+this.iotConfigs.Radiator_Lack_Start_Bind_Port+")：是","Radiator_LackState")
      }else{
        this.onDateRecover(this.sceneSystemData.Radiator_LackState,"Radiator_LackState")
        this.onCurrentData("是否缺水("+this.iotConfigs.Radiator_Lack_Start_Bind_Port+")：否","Radiator_LackState")
      }
    },
    'sceneSystemData.RadiatorCombination_describe':function (){
      this.onCurrentData(this.sceneSystemData.RadiatorCombination_describe,"RadiatorCombination_describe")
    },

    //电系统场景
    'sceneSystemData.IOT_SwitchingHouse_DoorState':function (){
      if(this.sceneSystemData.IOT_SwitchingHouse_DoorState==1){
        this.onDateChange(this.sceneSystemData.IOT_SwitchingHouse_DoorState,"IOT_SwitchingHouse_DoorState")
        this.onCurrentData("机房门状态("+this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port+")：打开","iot_switchinghouse_doorstate")
      }else{
        this.onDateRecover(this.sceneSystemData.IOT_SwitchingHouse_DoorState,"IOT_SwitchingHouse_DoorState")
        this.onCurrentData("机房门状态("+this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port+")：关闭","iot_switchinghouse_doorstate")
      }
    },
    'sceneSystemData.SwitchingHouse_Person_Status':function (){
      if(this.sceneSystemData.SwitchingHouse_Person_Status==1){
        this.onDateChange(this.sceneSystemData.SwitchingHouse_Person_Status,"SwitchingHouse_Person_Status")
        this.onCurrentData("人员状态：有人","switchinghouse_person_status")
      }else{
        this.onDateRecover(this.sceneSystemData.WaterPumpHouse_Person_Status,"SwitchingHouse_Person_Status")
        this.onCurrentData("人员状态：无人","switchinghouse_person_status")
      }
    },
    'sceneSystemData.IOT_SwitchingHouse_FeedFan':function (){
      if(this.sceneSystemData.IOT_SwitchingHouse_FeedFan==1){
        this.onDateChange(this.sceneSystemData.IOT_SwitchingHouse_FeedFan,"IOT_SwitchingHouse_FeedFan")
        this.onCurrentData("送风机状态("+this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port+")：运行","iot_switchinghouse_fan")
      }else{
        this.onDateRecover(this.sceneSystemData.IOT_SwitchingHouse_FeedFan,"IOT_SwitchingHouse_FeedFan")
        this.onCurrentData("送风机状态("+this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port+")：停止","iot_switchinghouse_fan")
      }
    },
    'sceneSystemData.IOT_SwitchingHouse_ExhaustFan':function (){
      if(this.sceneSystemData.IOT_SwitchingHouse_ExhaustFan==1){
        this.onDateChange(this.sceneSystemData.IOT_SwitchingHouse_ExhaustFan,"IOT_SwitchingHouse_ExhaustFan")
        this.onCurrentData("排风机状态("+this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port+")：运行","iot_switchinghouse_exhaustfan")
      }else{
        this.onDateRecover(this.sceneSystemData.IOT_SwitchingHouse_ExhaustFan,"IOT_SwitchingHouse_ExhaustFan")
        this.onCurrentData("排风机状态("+this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port+")：停止","iot_switchinghouse_exhaustfan")
      }
    },
    'sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_1':function (){
      if(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_1==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_1,"SwitchingRoom_IncomingCabinetSwitch_1")
        this.onCurrentData("进线柜开关状态("+this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port+")：分闸","switchingroom_incomingcabinetswitch_1")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_1,"SwitchingRoom_IncomingCabinetSwitch_1")
        this.onCurrentData("进线柜开关状态("+this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port+")：合闸","switchingroom_incomingcabinetswitch_1")
      }
    },
    'sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_2':function (){
      if(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_2==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_2,"SwitchingRoom_IncomingCabinetSwitch_2")
        this.onCurrentData("进线柜开关状态("+this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port+")：分闸","switchingroom_incomingcabinetswitch_2")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_IncomingCabinetSwitch_2,"SwitchingRoom_IncomingCabinetSwitch_2")
        this.onCurrentData("进线柜开关状态("+this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port+")：合闸","switchingroom_incomingcabinetswitch_2")
      }
    },
    'sceneSystemData.SwitchingRoom_ContactCabinetSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_ContactCabinetSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_ContactCabinetSwitch,"SwitchingRoom_ContactCabinetSwitch")
        this.onCurrentData("联络柜开关状态("+this.iotConfigs.ContactCabinetSwitch_Bind_Port+")：合闸","switchingroom_contactcabinetswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_ContactCabinetSwitch,"SwitchingRoom_ContactCabinetSwitch")
        this.onCurrentData("联络柜开关状态("+this.iotConfigs.ContactCabinetSwitch_Bind_Port+")：分闸","switchingroom_contactcabinetswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_Group_1_ElectricSupplySwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_Group_1_ElectricSupplySwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Group_1_ElectricSupplySwitch,"SwitchingRoom_Group_1_ElectricSupplySwitch")
        this.onCurrentData("市电开关状态("+this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port+")：分闸","switchingroom_group_1_electricsupplyswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Group_1_ElectricSupplySwitch,"SwitchingRoom_Group_1_ElectricSupplySwitch")
        this.onCurrentData("市电开关状态("+this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port+")：合闸","switchingroom_group_1_electricsupplyswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_Group_1_GenSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_Group_1_GenSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Group_1_GenSwitch,"SwitchingRoom_Group_1_GenSwitch")
        this.onCurrentData("发电机开关状态("+this.iotConfigs.Group_1_GenSwitch_Bind_Port+")：合闸","switchingroom_group_1_genswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Group_1_GenSwitch,"SwitchingRoom_Group_1_GenSwitch")
        this.onCurrentData("发电机开关状态("+this.iotConfigs.Group_1_GenSwitch_Bind_Port+")：分闸","switchingroom_group_1_genswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_Group_2_ElectricSupplySwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_Group_2_ElectricSupplySwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Group_2_ElectricSupplySwitch,"SwitchingRoom_Group_2_ElectricSupplySwitch")
        this.onCurrentData("市电开关状态("+this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port+")：分闸","switchingroom_group_2_electricsupplyswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Group_2_ElectricSupplySwitch,"SwitchingRoom_Group_2_ElectricSupplySwitch")
        this.onCurrentData("市电开关状态("+this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port+")：合闸","switchingroom_group_2_electricsupplyswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_Group_2_GenSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_Group_2_GenSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Group_2_GenSwitch,"SwitchingRoom_Group_2_GenSwitch")
        this.onCurrentData("发电机开关状态("+this.iotConfigs.Group_2_GenSwitch_Bind_Port+")：合闸","switchingroom_group_2_genswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Group_2_GenSwitch,"SwitchingRoom_Group_2_GenSwitch")
        this.onCurrentData("发电机开关状态("+this.iotConfigs.Group_2_GenSwitch_Bind_Port+")：分闸","switchingroom_group_2_genswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_1_CompensationCabinetSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetSwitch,"SwitchingRoom_1_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜一开关状态("+this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port+")：分闸","switchingroom_1_compensationcabinetswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetSwitch,"SwitchingRoom_1_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜一开关状态("+this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port+")：合闸","switchingroom_1_compensationcabinetswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_1_CompensationCabinetCapacity':function (){
      if(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetCapacity==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetCapacity,"SwitchingRoom_1_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port+")：正常","switchingroom_1_compensationcabinetcapacity")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_1_CompensationCabinetCapacity,"SwitchingRoom_1_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port+")：停止","switchingroom_1_compensationcabinetcapacity")
      }
    },
    'sceneSystemData.SwitchingRoom_2_CompensationCabinetSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetSwitch,"SwitchingRoom_2_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜二开关状态("+this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port+")：分闸","switchingroom_2_compensationcabinetswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetSwitch,"SwitchingRoom_2_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜二开关状态("+this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port+")：合闸","switchingroom_2_compensationcabinetswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_2_CompensationCabinetCapacity':function (){
      if(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetCapacity==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetCapacity,"SwitchingRoom_2_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port+")：正常","switchingroom_2_compensationcabinetcapacity")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_2_CompensationCabinetCapacity,"SwitchingRoom_2_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port+")：停止","switchingroom_2_compensationcabinetcapacity")
      }
    },
    'sceneSystemData.SwitchingRoom_3_CompensationCabinetSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetSwitch,"SwitchingRoom_3_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜三开关状态("+this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port+")：分闸","switchingroom_3_compensationcabinetswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetSwitch,"SwitchingRoom_3_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜三开关状态("+this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port+")：合闸","switchingroom_3_compensationcabinetswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_3_CompensationCabinetCapacity':function (){
      if(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetCapacity==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetCapacity,"SwitchingRoom_3_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port+")：正常","switchingroom_3_compensationcabinetcapacity")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_3_CompensationCabinetCapacity,"SwitchingRoom_3_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port+")：停止","switchingroom_3_compensationcabinetcapacity")
      }
    },
    'sceneSystemData.SwitchingRoom_4_CompensationCabinetSwitch':function (){
      if(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetSwitch==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetSwitch,"SwitchingRoom_4_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜四开关状态("+this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port+")：分闸","switchingroom_4_compensationcabinetswitch")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetSwitch,"SwitchingRoom_4_CompensationCabinetSwitch")
        this.onCurrentData("补偿柜四开关状态("+this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port+")：合闸","switchingroom_4_compensationcabinetswitch")
      }
    },
    'sceneSystemData.SwitchingRoom_4_CompensationCabinetCapacity':function (){
      if(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetCapacity==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetCapacity,"SwitchingRoom_4_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port+")：正常","switchingroom_4_compensationcabinetcapacity")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_4_CompensationCabinetCapacity,"SwitchingRoom_4_CompensationCabinetCapacity")
        this.onCurrentData("补偿电容投入状态("+this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port+")：停止","switchingroom_4_compensationcabinetcapacity")
      }
    },
    'sceneSystemData.SwitchingRoom_1_A_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_1_A_FeederCircuit,"SwitchingRoom_1_A_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_1_A_FeederCircuit>=this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_1_A_FeederCircuit,"SwitchingRoom_1_A_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_1_A_FeederCircuit,"SwitchingRoom_1_A_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_1_B_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_1_B_FeederCircuit,"SwitchingRoom_1_B_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_1_B_FeederCircuit>=this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_1_B_FeederCircuit,"SwitchingRoom_1_B_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_1_B_FeederCircuit,"SwitchingRoom_1_B_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_1_C_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_1_C_FeederCircuit,"SwitchingRoom_1_C_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_1_C_FeederCircuit>=this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_1_C_FeederCircuit,"SwitchingRoom_1_C_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_1_C_FeederCircuit,"SwitchingRoom_1_C_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_2_A_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_2_A_FeederCircuit,"SwitchingRoom_2_A_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_2_A_FeederCircuit>=this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_2_A_FeederCircuit,"SwitchingRoom_2_A_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_2_A_FeederCircuit,"SwitchingRoom_2_A_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_2_B_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_2_B_FeederCircuit,"SwitchingRoom_2_B_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_2_B_FeederCircuit>=this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_2_B_FeederCircuit,"SwitchingRoom_2_B_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_2_B_FeederCircuit,"SwitchingRoom_2_B_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_2_C_FeederCircuit':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_2_C_FeederCircuit,"SwitchingRoom_2_C_FeederCircuit")
      if(this.sceneSystemData.SwitchingRoom_2_C_FeederCircuit>=this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_2_C_FeederCircuit,"SwitchingRoom_2_C_FeederCircuit")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_2_C_FeederCircuit,"SwitchingRoom_2_C_FeederCircuit")
      }
    },
    'sceneSystemData.SwitchingRoom_MoistureProofLamps':function (){
      if(this.sceneSystemData.SwitchingRoom_MoistureProofLamps==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_MoistureProofLamps,"SwitchingRoom_MoistureProofLamps")
        this.onCurrentData("防潮灯状态("+this.iotConfigs.MoistureProofLamps_Bind_Port+")：开启","switchingroom_moistureprooflamps")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_MoistureProofLamps,"SwitchingRoom_MoistureProofLamps")
        this.onCurrentData("防潮灯状态("+this.iotConfigs.MoistureProofLamps_Bind_Port+")：关闭","switchingroom_moistureprooflamps")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Temp':function (){
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_Temp==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_Temp,"SwitchingRoom_Transformer_1_Temp")
        this.onCurrentData("变压器温度("+this.iotConfigs.Group_1_Transformer_Temp_Bind_Port+")：异常","switchingroom_transformer_1_temp")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_Temp,"SwitchingRoom_Transformer_1_Temp")
        this.onCurrentData("变压器温度("+this.iotConfigs.Group_1_Transformer_Temp_Bind_Port+")：正常","switchingroom_transformer_1_temp")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Fan':function (){
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_Fan==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_Fan,"SwitchingRoom_Transformer_1_Fan")
        this.onCurrentData("变压器降温风扇("+this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port+")：开启","switchingroom_transformer_1_fan")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_Fan,"SwitchingRoom_Transformer_1_Fan")
        this.onCurrentData("变压器降温风扇("+this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port+")：关闭","switchingroom_transformer_1_fan")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Temp':function (){
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_Temp==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_Temp,"SwitchingRoom_Transformer_2_Temp")
        this.onCurrentData("变压器温度("+this.iotConfigs.Group_2_Transformer_Temp_Bind_Port+")：异常","switchingroom_transformer_2_temp")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_Temp,"SwitchingRoom_Transformer_2_Temp")
        this.onCurrentData("变压器温度("+this.iotConfigs.Group_2_Transformer_Temp_Bind_Port+")：正常","switchingroom_transformer_2_temp")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Fan':function (){
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_Fan==1){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_Fan,"SwitchingRoom_Transformer_2_Fan")
        this.onCurrentData("变压器降温风扇("+this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port+")：开启","switchingroom_transformer_2_fan")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_Fan,"SwitchingRoom_Transformer_2_Fan")
        this.onCurrentData("变压器降温风扇("+this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port+")：关闭","switchingroom_transformer_2_fan")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_LoadRate':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_LoadRate+"%","SwitchingRoom_Transformer_1_LoadRate")
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_LoadRate<this.iotConfigs.Transformer_LoadRate_Minimum_Threshold*100){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_LoadRate,"SwitchingRoom_Transformer_1_LoadRate")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_LoadRate,"SwitchingRoom_Transformer_1_LoadRate")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_LoadRate':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_LoadRate+"%","SwitchingRoom_Transformer_2_LoadRate")
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_LoadRate<this.iotConfigs.Transformer_LoadRate_Minimum_Threshold*100){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_LoadRate,"SwitchingRoom_Transformer_2_LoadRate")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_LoadRate,"SwitchingRoom_Transformer_2_LoadRate")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Uab':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Uab,"SwitchingRoom_Transformer_1_Uab")
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_Uab>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_1_Uab<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_Uab,"SwitchingRoom_Transformer_1_Uab")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_Uab,"SwitchingRoom_Transformer_1_Uab")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Ubc':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Ubc,"SwitchingRoom_Transformer_1_Ubc")
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_Ubc>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_1_Ubc<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_Ubc,"SwitchingRoom_Transformer_1_Ubc")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_Ubc,"SwitchingRoom_Transformer_1_Ubc")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Uca':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Uca,"SwitchingRoom_Transformer_1_Uca")
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_Uca>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_1_Uca<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_Uca,"SwitchingRoom_Transformer_1_Uca")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_Uca,"SwitchingRoom_Transformer_1_Uca")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Ia':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Ia,"SwitchingRoom_Transformer_1_Ia")
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Ib':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Ib,"SwitchingRoom_Transformer_1_Ib")
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Ic':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_Ic,"SwitchingRoom_Transformer_1_Ic")
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_P':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_P,"SwitchingRoom_Transformer_1_P")
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_COS':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_COS,"SwitchingRoom_Transformer_1_COS")
      if(this.sceneSystemData.SwitchingRoom_Transformer_1_COS<this.iotConfigs.Transformer_1_Cos_Minimum_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_1_COS,"SwitchingRoom_Transformer_1_COS")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_1_COS,"SwitchingRoom_Transformer_1_COS")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_F':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_1_F,"SwitchingRoom_Transformer_1_F")
    },
    'sceneSystemData.SwitchingRoom_Transformer_1_Q':function (){
      this.onCurrentData(parseInt(this.sceneSystemData.SwitchingRoom_Transformer_1_Q),"SwitchingRoom_Transformer_1_Q")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Uab':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Uab,"SwitchingRoom_Transformer_2_Uab")
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_Uab>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_2_Uab<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_Uab,"SwitchingRoom_Transformer_2_Uab")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_Uab,"SwitchingRoom_Transformer_2_Uab")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Ubc':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Ubc,"SwitchingRoom_Transformer_2_Ubc")
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_Ubc>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_2_Ubc<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_Ubc,"SwitchingRoom_Transformer_2_Ubc")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_Ubc,"SwitchingRoom_Transformer_2_Ubc")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Uca':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Uca,"SwitchingRoom_Transformer_2_Uca")
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_Uca>this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold||this.sceneSystemData.SwitchingRoom_Transformer_2_Uca<this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_Uca,"SwitchingRoom_Transformer_2_Uca")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_Uca,"SwitchingRoom_Transformer_2_Uca")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Ia':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Ia,"SwitchingRoom_Transformer_2_Ia")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Ib':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Ib,"SwitchingRoom_Transformer_2_Ib")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Ic':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_Ic,"SwitchingRoom_Transformer_2_Ic")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_P':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_P,"SwitchingRoom_Transformer_2_P")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_COS':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_COS,"SwitchingRoom_Transformer_2_COS")
      if(this.sceneSystemData.SwitchingRoom_Transformer_2_COS<this.iotConfigs.Transformer_2_Cos_Minimum_Threshold){
        this.onDateChange(this.sceneSystemData.SwitchingRoom_Transformer_2_COS,"SwitchingRoom_Transformer_2_COS")
      }else{
        this.onDateRecover(this.sceneSystemData.SwitchingRoom_Transformer_2_COS,"SwitchingRoom_Transformer_2_COS")
      }
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_F':function (){
      this.onCurrentData(this.sceneSystemData.SwitchingRoom_Transformer_2_F,"SwitchingRoom_Transformer_2_F")
    },
    'sceneSystemData.SwitchingRoom_Transformer_2_Q':function (){
      this.onCurrentData(parseInt(this.sceneSystemData.SwitchingRoom_Transformer_2_Q),"SwitchingRoom_Transformer_2_Q")
    },
  },
  methods: {
    getStyle,
    getSVGStyle,

    onClick() {
      if(this.config.linkage.data[0].parameter==="水房运行数据"){
        this.waterSystemStatusVisible=true
      }if(this.config.linkage.data[0].parameter==="系统图"){
        getSceneInfoListByCondition({scenetemplateid:this.$store.state.scenetemplateid,sceneinfotype:1}).then(res=>{
          if(res&&res.returncode=='0'){
            if(res.item.length>0){
              this.picurl=this.baseurl+res.item[0].sceneinfopicurl
              this.picVisible=true
              window.open(this.picurl)
            }else{
              this.$message.error("未找到相关系统图文件")
            }
          }
        })
      }if(this.config.linkage.data[0].parameter==="平面图"){
        getSceneInfoListByCondition({scenetemplateid:this.$store.state.scenetemplateid,sceneinfotype:2}).then(res=>{
          if(res&&res.returncode=='0'){
            if(res.item.length>0){
              this.picurl=this.baseurl+res.item[0].sceneinfopicurl
              this.picVisible=true
              window.open(this.picurl)
            }else{
              this.$message.error("未找到相关平面图文件")
            }
          }
        })
      }else if(this.config.linkage.data[0].parameter==="低区水泵组"){
        this.waterSystemChartType="low_pump"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="中区水泵组"){
        this.waterSystemChartType="middle_pump"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="高区水泵组"){
        this.waterSystemChartType="high_pump"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="1#集水井"){
        this.waterSystemChartType="collecting_well_1"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="风机"){
        this.waterSystemChartType="fan_1"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="水房温湿度"){
        this.waterSystemChartType="water_room_temperature_humidity"
        this.waterSystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="电房运行数据"){
        this.electricitySystemStatusVisible=true
      }else if(this.config.linkage.data[0].parameter==="1#变压器"){
        this.electricitySystemChartType="transformer_1"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="2#变压器"){
        this.electricitySystemChartType="transformer_2"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="1#电量表"){
        this.electricitySystemChartType="electricity_meter_1"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="2#电量表"){
        this.electricitySystemChartType="electricity_meter_2"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="1#馈电回路"){
        this.electricitySystemChartType="feeder_circuit_1"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="2#馈电回路"){
        this.electricitySystemChartType="feeder_circuit_2"
        this.electricitySystemChartVisible=true
      }else if(this.config.linkage.data[0].parameter==="电房温湿度"){
        this.electricitySystemChartType="electric_room_temperature_humidity"
        this.electricitySystemChartVisible=true
      }else{
        const events = this.config.events
        Object.keys(events).forEach(event => {
          this[event](events[event])
        })
        eventBus.$emit('v-click', this.config.id)
      }
    },

    onMouseEnter() {
      eventBus.$emit('v-hover', this.config.id)
    },

    onMouseLeave() {
      eventBus.$emit('v-leave', this.config.id)
    },

    onDateChange(sceneSystemData,parameter) {
      if(this.config.linkage.data[0].parameter==parameter){
        eventBus.$emit('v-dataChange', this.config.id)
      }
    },

    onDateRecover(sceneSystemData,parameter) {
      if(this.config.linkage.data[0].parameter==parameter){
        eventBus.$emit('v-dataRecover', this.config.id)
      }
    },

    onCurrentData(sceneSystemData,parameter) {
      if(this.config.linkage.data[0].parameter==parameter){
        eventBus.$emit('v-currentData', this.config.id ,sceneSystemData)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.component {
  position: absolute;
}
</style>
