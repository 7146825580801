<template>
  <a-modal v-model="modalVisible" :closable="closable" :width="windowWidth" :dialogStyle="dialogStyle" :body-style="modalBodyStyle" :footer="null" @cancel="closeModal">
<!--    <template slot="footer">-->
<!--      <a-button type="primary" @click="closeModal()">关闭</a-button>-->
<!--    </template>-->
    <div
        class="canvas"
        :style="{
                      ...getCanvasStyle(canvasStyleData),
                      width: canvasWidth + 'px',
                      height: canvasHeight + 'px',
                      marginLeft: '-20px'
                  }"
    >
      <ComponentWrapper
          v-for="(item, index) in copyData"
          :key="index"
          :config="item"
          :sceneSystemData="sceneSystemData"
          :iotConfigs="detailData.iotConfigs"
          style="margin-top: -20px"
      />
    </div>
  </a-modal>
</template>

<script>
import { getStyle, getCanvasStyle } from '@/utils/style'
import { mapState } from 'vuex'
import ComponentWrapper from '../../components/Editor/ComponentWrapper'
import { changeStyleWithScale } from '@/utils/translate'
import { deepCopy } from '@/utils/utils'
import {getSceneTemplateById, getSceneTemplateListByCondition} from "A/jcgn";
import store from "@/store";
export default {
  name: "SceneTemplateDetail",
  components: { ComponentWrapper },
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      modalVisible: false,
      modalBodyStyle: {
        height: '770px',
      },
      closable:true,
      isScreenshot:false,
      copyData: [],
      deviceinfos:[],
      ws: null,
      interval: null,
      sceneSystemData:{},
      windowWidth:1208,
      canvasWidth:1200,
      canvasHeight:760,
      widthRadio:'',
      heightRadio:'',
      dialogStyle:{
        top:0,
      }
    }
  },
  computed: {
    ...mapState({
      fullScreen: state => state.main.fullScreen,
      canvasStyleData: state => state.canvasStyleData,
      socketMessage: state => state.socketMessage,
    })
  },
  watch:{
    fullScreen(newValue){
      if(newValue){
        this.closable=false
        this.windowWidth=document.documentElement.clientWidth-10
        this.canvasWidth=document.documentElement.clientWidth-20
        this.canvasHeight=document.documentElement.clientHeight-26
        this.modalBodyStyle.height=(this.canvasHeight+10)+'px'
        this.widthRadio = this.canvasWidth/1200
        this.heightRadio = this.canvasHeight/750
        for(let i=0;i<this.copyData.length;i++){
          this.copyData[i].style.top=this.copyData[i].style.top*this.heightRadio
          this.copyData[i].style.left=this.copyData[i].style.left*this.widthRadio
          if(this.copyData[i].component=="LineShape"&&this.copyData[i].style.height<5) {
            this.copyData[i].style.width = this.copyData[i].style.width * this.widthRadio
          }else if(this.copyData[i].component=="SVGTriangle"&&this.copyData[i].style.rotate==90){
            this.copyData[i].style.width=this.copyData[i].style.width*this.heightRadio
            this.copyData[i].style.height=this.copyData[i].style.height*this.widthRadio
          }else{
            this.copyData[i].style.width=this.copyData[i].style.width*this.widthRadio
            this.copyData[i].style.height=this.copyData[i].style.height*this.heightRadio
          }
          if(this.canvasWidth>1600) {
            this.copyData[i].style.fontSize = this.copyData[i].style.fontSize + 8
            this.copyData[i].style.fontWeight = this.copyData[i].style.fontWeight + 100
          }
        }
      }else{
        this.closable=true
        this.windowWidth=1208
        this.canvasWidth=changeStyleWithScale(this.canvasStyleData.width)
        this.canvasHeight=changeStyleWithScale(this.canvasStyleData.height)
        this.modalBodyStyle.height='770px'
        for(let i=0;i<this.copyData.length;i++){
          this.copyData[i].style.top=this.copyData[i].style.top/this.heightRadio
          this.copyData[i].style.left=this.copyData[i].style.left/this.widthRadio
          if(this.copyData[i].component=="LineShape"&&this.copyData[i].style.height<5) {
            this.copyData[i].style.width = this.copyData[i].style.width / this.widthRadio
          }else if(this.copyData[i].component=="SVGTriangle"&&this.copyData[i].style.rotate==90){
            this.copyData[i].style.width=this.copyData[i].style.width/this.heightRadio
            this.copyData[i].style.height=this.copyData[i].style.height/this.widthRadio
          }else{
            this.copyData[i].style.width=this.copyData[i].style.width/this.widthRadio
            this.copyData[i].style.height=this.copyData[i].style.height/this.heightRadio
          }
          if(this.canvasWidth<1600){
            this.copyData[i].style.fontSize=this.copyData[i].style.fontSize - 8
            this.copyData[i].style.fontWeight=this.copyData[i].style.fontWeight-100
          }
        }
      }
    },
    visible(val) {
      if(val) {
        this.sceneSystemData={}
        this.modalVisible = true;
        clearInterval(this.interval);
        this.interval=null
        this.$ws.disconnect();
        this.$store.state.scenetemplateid=this.detailData.scenetemplateid
        this.getSceneTemplateList()
        // this.getSceneTemplateById()
        // this.$set(this, 'copyData', deepCopy(this.componentData))
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    socketMessage(val) {
      if (val) {
        this.sceneSystemData=val.data
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval=null
    this.$ws.disconnect();
    store.commit('main/setFullScreen', false)
    this.closable=true
  },
  destroyed() {
    clearInterval(this.interval);
    this.interval=null
    this.$ws.disconnect();
    store.commit('main/setFullScreen', false)
    this.closable=true
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getStyle,
    getCanvasStyle,
    changeStyleWithScale,

    getSceneTemplateList(){
      getSceneTemplateListByCondition({scenetemplateid:this.detailData.scenetemplateid,templatename:this.detailData.templatename}).then(res=>{
        if(res&&res.returncode=='0'){
          this.scenetype=res.item[0].scenetype
          this.componentData=JSON.parse(res.item[0].canvasdata)
          this.$set(this, 'copyData', deepCopy(this.componentData))
          if (res.item[0].canvasstyle) {
            this.$store.commit('setCanvasStyle', JSON.parse(res.item[0].canvasstyle))
          }
          this.getSceneTemplateById()
        }
      })
    },
    getSceneTemplateById(){
      getSceneTemplateById({scenetemplateid:this.detailData.scenetemplateid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.deviceinfos=res.item.deviceinfos
          this.$ws.connect((ws) => {
            this.ws = ws;
            if(this.deviceinfos) {
              this.sendScenes();
            }
          });
        }
      })
    },
    sendScenes() {
      if(this.interval) {
        // 已启动连接
        return;
      }
      if(this.ws && this.deviceinfos) {
        for(let i=0;i<this.deviceinfos.length;i++){
          let wsParams = {
            devicenum: this.deviceinfos[i].devicenum,
            terminalmodlenum: this.scenetype===1?'03':'04',
          }
          this.ws.send(JSON.stringify(wsParams));
          this.interval = setInterval(() => {
            this.ws.send(JSON.stringify(wsParams));
          }, 45*1000);
        }
      }
    },
    closeModal(){
      clearInterval(this.interval);
      this.$ws.disconnect();
      this.modalVisible=false
    },
  },
}
</script>
<style lang="scss" scoped>
</style>