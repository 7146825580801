<template>
  <div class="base-info-contoiner">
    <a-row class="detail-form-row">
      <a-col :span="12">项目名称: <span :title="formDatas.userdepname" class="value-text">{{formDatas.userdepname}}</span></a-col>
      <a-col :span="12">项目地址: <span :title="formDatas.address" class="value-text">{{formDatas.address}}</span></a-col>
      <a-col :span="12">项目负责人姓名: <span :title="formDatas.depmanager" class="value-text">{{formDatas.depmanager}}</span></a-col>
      <a-col :span="12">项目负责人电话: <span :title="formDatas.depmanagerphone" class="value-text">{{formDatas.depmanagerphone}}</span></a-col>
      <a-col :span="12">片区负责人姓名: <span :title="formDatas.regionmanager" class="value-text">{{formDatas.regionmanager}}</span></a-col>
      <a-col :span="12">片区负责人电话: <span :title="formDatas.regionmanagerphone" class="value-text">{{formDatas.regionmanagerphone}}</span></a-col>
      <a-col :span="12">督导负责人姓名: <span :title="formDatas.itemsmanager" class="value-text">{{formDatas.itemsmanager}}</span></a-col>
      <a-col :span="12">督导负责人电话: <span :title="formDatas.itemsmanagerphone" class="value-text">{{formDatas.itemsmanagerphone}}</span></a-col>
      <a-col :span="12">品质负责人姓名: <span :title="formDatas.projectmanager" class="value-text">{{formDatas.projectmanager}}</span></a-col>
      <a-col :span="12">品质负责人电话: <span :title="formDatas.projectmanagerphone" class="value-text">{{formDatas.projectmanagerphone}}</span></a-col>
      <a-col :span="12">值班室电话: <span :title="formDatas.dutyroomphone" class="value-text">{{formDatas.dutyroomphone}}</span></a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'
import {getMonitorPointListByCondition} from "A/monitoring";
export default {
  props: ['monitorpointnum'],
  data() {
    return {
      moment,
      formDatas: {
        monitorid:'',
        remotecode:'',
        userdepid: '',
        parentid: '',
        userdepname: '',
        deptype: '',
        levelid: '',
        abbreviation: '',
        dutyroomphone:'',
        sequenceno: 1,
        areaList: [],
        areacode: '',
        address: '',
        telphone: '',
        mailcode: '',
        depmanager: '',
        depmanagerphone: '',
        regionmanager: '',
        regionmanagerphone: '',
        citymanager: '',
        citymanagerphone: '',
        areamanager: '',
        areamanagerphone: '',
        itemsmanager: '',
        itemsmanagerphone: '',
        projectmanager: '',
        projectmanagerphone: '',
        legalperson: '',
        legalphone: '',
        legalid: '',
        coordinatex: '',
        coordinatey: '',
        certificatenum: '',
        certificatepic: '',
        depnum: '',
        deppic: '',
        businessnum: '',
        businesspic: '',
        licensenum: '',
        licensepic: '',
        propertyid: '',
        userdepdesc: '',
        createtime:'',
        updatetime:'',
        operator1:'',
        operator1phone:'',
        operator2:'',
        operator2phone:'',
        operator3:'',
        operator3phone:'',
        operator4:'',
        operator4phone:'',
      },
    }
  },
  watch: {
    'monitorpointnum'(val){
      if(val){
        this.initDetail();
      }
    }
  },
  created() {
    this.initDetail();
  },
  methods: {
    initDetail() {
      if(this.monitorpointnum) {
        let params = {
          monitorpointnum: this.monitorpointnum
        }
        getMonitorPointListByCondition(params).then(res => {
          if(res && res.returncode == '0') {
            this.formDatas=res.item[0]
          }
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.base-info-contoiner {
  margin-top: 16px;
}
</style>