<template>
  <a-modal v-model="modalVisible" title="运行数据" :width="1250" :centered="true" @cancel="closeModal">
    <template slot="footer">
      <a-button type="primary" @click="closeModal()">关闭</a-button>
    </template>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" :label-col="{span:14}" :wrapper-col="{span:10}">
            <a-form-model-item label="水箱容积" style="width: 15%">
              <span>{{param1}}</span>
            </a-form-model-item>
            <a-form-model-item label="高区供水压力" style="width: 18%">
              <span>{{param2}}</span>
            </a-form-model-item>
            <a-form-model-item label="低区供水压力" style="width: 18%">
              <span>{{param3}}</span>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">实际运行数据<span style="color: red">（如果停止时点为空，则表示该设备仍在运行，运行时间是当前时点和起始时点之差）</span></h3>
          <div class="table-btns">
<!--            <a-button style="margin-right: 10px" type="primary" @click="SceneMaintainDetail('params')">水系统标准参数</a-button>-->
            <a-button style="margin-right: 10px" type="primary" @click="SceneMaintainDetail('maintain')">保养时间</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="columns1" :data-source="data1" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
        <a-table :columns="columns2" :data-source="data2" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
        <a-table :columns="columns3" :data-source="data3" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
        <a-table :columns="columns4" :data-source="data4" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minnumdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxnumdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
      </div>
    </div>
    <scene-maintain-detail :visible.sync="maintainModalVisible" :show-type="maintainModalShowType" :maintainDetailData="maintainDetailData" @get-operation-result="getTableData"></scene-maintain-detail>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import {getTimeRange} from "U/index";
import {getSceneRunDataList} from "A/monitoring";
import SceneMaintainDetail from "C/modals/SceneMaintainDetail";
import {getSceneInfoListByCondition} from "A/patrol";
export default {
  name: "StatusData",
  components: {SceneMaintainDetail},
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      data1: [
        {
          num: '1',
          supply:'高区供水',
          name:'1#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '2',
          supply:'高区供水',
          name:'2#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '3',
          supply:'高区供水',
          name:'3#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '4',
          supply:'高区供水',
          name:'4#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '5',
          supply:'低区供水',
          name:'1#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '6',
          supply:'低区供水',
          name:'2#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '7',
          supply:'低区供水',
          name:'3#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '8',
          supply:'低区供水',
          name:'4#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
      ],
      data2: [
        {
          num: '1',
          supply:'1#集水井',
          name:'1#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '2',
          supply:'1#集水井',
          name:'2#泵',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
      ],
      data3: [
        {
          num: '1',
          supply:'生活水泵房',
          name:'排风机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '2',
          supply:'生活水泵房',
          name:'送风机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
      ],
      data4: [
        {
          num: '1',
          name:'水箱',
          averagenum:'',
          minnum:'',
          minnumdate:'',
          maxnum:'',
          maxnumdate:'',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
        },
      ],
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        starttime:'',
        endtime:'',
      },
      columns1: [
        {
          title: '区域/名称',
          dataIndex: 'area',
          children:[{
            title: '供水区域',
            dataIndex: 'supply',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if(index==0){
                obj.attrs.rowSpan = 4;
              }else if (index==4){
                obj.attrs.rowSpan = 4;
              }else{
                obj.attrs.rowSpan = 0;
              }
              return obj;
            },
          },{
            title: '水泵名称',
            dataIndex: 'name',
          }],
        },
        {
          title: '最短运行时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长运行时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
            scopedSlots: { customRender: 'maxminute' },
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
        {
          title: '累计时间',
          dataIndex: 'time',
          children:[{
            title: '本次累计运行时间(小时)',
            dataIndex: 'thistime',
            width:'80px'
          },{
            title: '上次保养时间',
            dataIndex: 'laterdate',
            scopedSlots: { customRender: 'laterdate' },
          },{
            title: '总运行时间(小时)',
            dataIndex: 'totaltime',
          }]
        },
      ],
      columns2: [
        {
          title: '区域/名称',
          dataIndex: 'area',
          children:[{
            title: '排水区域',
            dataIndex: 'supply',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if(index==0){
                obj.attrs.rowSpan = 2;
              }else if (index==2){
                obj.attrs.rowSpan = 2;
              }else{
                obj.attrs.rowSpan = 0;
              }
              return obj;
            },
          },{
            title: '水泵名称',
            dataIndex: 'name',
          }],
        },
        {
          title: '最短运行时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长运行时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
        {
          title: '累计时间',
          dataIndex: 'time',
          children:[{
            title: '本次累计运行时间(小时)',
            dataIndex: 'thistime',
            width:'80px'
          },{
            title: '上次保养时间',
            dataIndex: 'laterdate',
            scopedSlots: { customRender: 'laterdate' },
          },{
            title: '总运行时间(小时)',
            dataIndex: 'totaltime',
          }]
        },
      ],
      columns3: [
        {
          title: '区域/名称',
          dataIndex: 'area',
          children:[{
            title: '通风区域',
            dataIndex: 'supply',
            customRender: (value, row, index) => {
              const obj = {
                children: value,
                attrs: {},
              };
              if(index==0){
                obj.attrs.rowSpan = 2;
              }else if (index==2){
                obj.attrs.rowSpan = 2;
              }else{
                obj.attrs.rowSpan = 0;
              }
              return obj;
            },
          },{
            title: '风机名称',
            dataIndex: 'name',
          }],
        },
        {
          title: '最短运行时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长运行时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
        {
          title: '累计时间',
          dataIndex: 'time',
          children:[{
            title: '本次累计运行时间(小时)',
            dataIndex: 'thistime',
            width:'80px'
          },{
            title: '上次保养时间',
            dataIndex: 'laterdate',
            scopedSlots: { customRender: 'laterdate' },
          },{
            title: '总运行时间(小时)',
            dataIndex: 'totaltime',
          }]
        },
      ],
      columns4: [
        {
          title: '名称',
          dataIndex: 'name',
        },
        {
          title: '补水次数',
          dataIndex: 'num',
          children:[{
            title: '平均每天次数',
            dataIndex: 'averagenum',
          },{
            title: '一天最少次数',
            dataIndex: 'minnum',
          },{
            title: '最少次数发生日期',
            dataIndex: 'minnumdate',
            scopedSlots: { customRender: 'minnumdate' },
          },{
            title: '一天最多次数',
            dataIndex: 'maxnum',
          },{
            title: '最多次数发生日期',
            dataIndex: 'maxnumdate',
            scopedSlots: { customRender: 'maxnumdate' },
          }]
        },
        {
          title: '最短补水时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长补水时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
      ],
      param1:'',
      param2:'',
      param3:'',
      maintainModalVisible: false,
      maintainModalShowType: '',
      maintainDetailData: {
        scenetemplateid: '',
        scenetype:''
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "生活水系统运行数据"
    },
    breadcrumb() {
      const pages = [{name:"场景管理",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
  },
  methods:{
    closeModal(){
      this.modalVisible=false
    },
    getTableData() {
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        scenetemplateid:this.$store.state.scenetemplateid,
        scenetype: 1,
      };
      getSceneRunDataList(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.data1 = res.data1;
          this.data2 = res.data2;
          this.data3 = res.data3;
          this.data4 = res.data4;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
      getSceneInfoListByCondition({scenetemplateid:this.$store.state.scenetemplateid}).then(res=>{
        if(res && res.returncode == '0') {
          this.param1=res.item[0].param1
          this.param2=res.item[0].param2
          this.param3=res.item[0].param3
        }
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    SceneMaintainDetail(type){
      this.maintainDetailData.scenetemplateid=this.$store.state.scenetemplateid
      this.maintainDetailData.scenetype=1
      this.maintainModalShowType=type
      this.maintainModalVisible=true
    }
  },
}
</script>
<style scoped>

</style>