<template>
  <div class="base-info-contoiner">
    <div v-for="(item,index) in list">
      <a-button :style="style[index]" @click="playVideo(item,index)" ref="decChange" :key="index">
        {{item.monitorname?item.monitorname:item.devicename}}
        （<span :style="item.isonline==1?{color:'#a0d911',fontWeight:'bold'}:{color:'red',fontWeight:'bold'}">{{item.isonline==1?"在线":"离线"}}</span>）
      </a-button>
      <a-button v-if="item.scenetemplateid&&item.scenetype==1" type="primary" @click="gotoScene(item)" style="margin-left: 10px">生活水系统场景(物联网)</a-button>
      <a-button v-if="item.scenetemplateid&&item.scenetype==2" type="primary" @click="gotoScene(item)" style="margin-left: 10px">变配电系统场景(物联网)</a-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  props: ['list'],
  data() {
    return {
      moment,
      style:[
        {
          index:0,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:1,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:2,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:3,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:4,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:5,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:6,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:7,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:8,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:9,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
      ],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: {
        scenetemplateid:'',
        templatename:'',
      },
    }
  },
  created() {
    this.style[0].background='rgba(35,109,246,0.9)'
  },
  methods: {
    playVideo(item,index){
      this.$nextTick(()=>{
        this.style.filter(item=>{
          if(item.index==index){
            item.background='rgba(35,109,246,0.9)'
          }else{
            item.background='rgba(0,0,0,0.55)'
          }
        })
      })
      this.$emit('playVideo',{monitorname:item.monitorname?item.monitorname:item.devicename,waittime:item.waittime,monitorid:item.monitornum?item.monitornum:item.devicenum,equipmenttype:item.equipmenttype,terminaltype:item.terminaltype,isremote:item.isremote,remoteurl:item.remoteurl})
    },
    gotoScene(item){
      this.modalDetailData.scenetemplateid=item.scenetemplateid
      this.modalDetailData.templatename=item.templatename
      this.modalVisible=true
      this.$emit('sceneDetail',{modalVisible:true,modalDetailData:this.modalDetailData})
    }
  }
}
</script>
<style lang="scss" scoped>
.base-info-contoiner {
  margin-top: 16px;
}
</style>