<template>
  <a-modal v-model="modalVisible" title="运行数据" :width="1250" :centered="true" @cancel="closeModal">
    <template slot="footer">
      <a-button type="primary" @click="closeModal()">关闭</a-button>
    </template>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" :label-col="{span:16}" :wrapper-col="{span:8}">
            <a-form-model-item label="1#电压器容量" style="width: 18%">
              <span>{{param1}}</span>
            </a-form-model-item>
            <a-form-model-item label="2#电压器容量" style="width: 18%">
              <span>{{param2}}</span>
            </a-form-model-item>
            <a-form-model-item label="发电机功率" style="width: 17%">
              <span>{{param3}}</span>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 25%">
              <a-range-picker v-model="queryInstallDate"></a-range-picker>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">实际运行数据<span style="color: red">（如果停止时点为空，则表示该设备仍在运行，运行时间是当前时点和起始时点之差）</span></h3>
          <div class="table-btns">
            <a-button style="margin-right: 10px" type="primary" @click="SceneMaintainDetail('maintain')">保养时间</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="columns1" :data-source="data1" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
        <a-table :columns="columns2" :data-source="data2" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minhappentime1" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxhappentime1" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minhappentime2" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxhappentime2" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
        <a-table :columns="columns3" :data-source="data3" :bordered="true" :rowKey="(record,index)=> index" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="minstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="minendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maxendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="laterdate" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
        </a-table>
      </div>
    </div>
    <scene-maintain-detail :visible.sync="maintainModalVisible" :show-type="maintainModalShowType" :maintainDetailData="maintainDetailData" @get-operation-result="getTableData"></scene-maintain-detail>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import {getTimeRange} from "U/index";
import {getSceneRunDataList} from "A/monitoring";
import SceneMaintainDetail from "C/modals/SceneMaintainDetail";
import {getSceneInfoListByCondition} from "A/patrol";
export default {
  name: "StatusData",
  components: {SceneMaintainDetail},
  props: {
    visible: {
      default: false
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalVisible: false,
      queryInstallDate:null,
      queryParams:{
        monitorpointname:'',
        starttime:'',
        endtime:'',
      },
      data1: [
        {
          num: '1',
          tablename:'开关状态',
          name:'1受电开关分闸',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '2',
          tablename:'开关状态',
          name:'2受电开关分闸',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '3',
          tablename:'开关状态',
          name:'母联开关合闸',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '4',
          tablename:'开关状态',
          name:'发电开关合闸',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '5',
          tablename:'开关状态',
          name:'11电容投入',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '6',
          tablename:'开关状态',
          name:'12电容投入',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '7',
          tablename:'开关状态',
          name:'21电容投入',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
        {
          num: '8',
          tablename:'开关状态',
          name:'22电容投入',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          totaltime:'',
        },
      ],
      data2: [
        {
          num: '1',
          tablename:'电量数据',
          name1:'Uab(V)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Uab(V)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '2',
          tablename:'电量数据',
          name1:'Ubc(V)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Ubc(V)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '3',
          tablename:'电量数据',
          name1:'Uca(V)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Uca(V)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '4',
          tablename:'电量数据',
          name1:'Ia(A)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Ia(A)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '5',
          tablename:'电量数据',
          name1:'Ib(A)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Ic(A)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '6',
          tablename:'电量数据',
          name1:'Ic(A)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'Ic(A)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '7',
          tablename:'电量数据',
          name1:'P(KVA)',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'P(KVA)',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '8',
          tablename:'电量数据',
          name1:'COS',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'COS',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
        {
          num: '9',
          tablename:'电量数据',
          name1:'变压器负载率',
          mintime1: '',
          minnumber1: '',
          minhappentime1:'',
          maxtime1:'',
          maxnumber1:'',
          maxhappentime1:'',
          name2:'变压器负载率',
          mintime2: '',
          minnumber2: '',
          minhappentime2:'',
          maxtime2:'',
          maxnumber2:'',
          maxhappentime2:'',
        },
      ],
      data3: [
        {
          num: '1',
          tablename:'设施状态',
          name:'变压器风机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '2',
          tablename:'设施状态',
          name:'防潮机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '3',
          tablename:'设施状态',
          name:'排风机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
        {
          num: '4',
          tablename:'设施状态',
          name:'送风机',
          minminute: '',
          minstarttime:'',
          minendtime:'',
          maxminute:'',
          maxstarttime:'',
          maxendtime:'',
          thistime:'',
          laterdate:'',
          totaltime:'',
        },
      ],
      columns1: [
        {
          title: '名称',
          dataIndex: 'tablename',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if(index==0){
              obj.attrs.rowSpan = 8;
            }else{
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: '开关名称',
          dataIndex: 'name',
        },
        {
          title: '最短动作时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长动作时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
            scopedSlots: { customRender: 'maxminute' },
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
        {
          title: '总动作时间(小时)',
          dataIndex: 'totaltime',
        },
      ],
      columns2: [
        {
          title: '名称',
          dataIndex: 'tablename',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if(index==0){
              obj.attrs.rowSpan = 9;
            }else{
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: '1回路',
          dataIndex: 'name1',
        },
        {
          title: '最小值时点',
          dataIndex: 'mintime1',
          children:[{
            title: '数值',
            dataIndex: 'minnumber1',
          },{
            title: '发生时间点',
            dataIndex: 'minhappentime1',
            scopedSlots: { customRender: 'minhappentime1' },
          }]
        },
        {
          title: '最大值时点',
          dataIndex: 'maxtime1',
          children:[{
            title: '数值',
            dataIndex: 'maxnumber1',
          },{
            title: '发生时间点',
            dataIndex: 'maxhappentime1',
            scopedSlots: { customRender: 'maxhappentime1' },
          }]
        },
        {
          title: '2回路',
          dataIndex: 'name2',
        },
        {
          title: '最小值时点',
          dataIndex: 'mintime2',
          children:[{
            title: '数值',
            dataIndex: 'minnumber2',
          },{
            title: '发生时间点',
            dataIndex: 'minhappentime2',
            scopedSlots: { customRender: 'minhappentime2' },
          }]
        },
        {
          title: '最大值时点',
          dataIndex: 'maxtime2',
          children:[{
            title: '数值',
            dataIndex: 'maxnumber2',
          },{
            title: '发生时间点',
            dataIndex: 'maxhappentime2',
            scopedSlots: { customRender: 'maxhappentime2' },
          }]
        },
      ],
      columns3: [
        {
          title: '名称',
          dataIndex: 'tablename',
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {},
            };
            if(index==0){
              obj.attrs.rowSpan = 5;
            }else{
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: '设施名称',
          dataIndex: 'name',
        },
        {
          title: '最短运行时间',
          dataIndex: 'mintime',
          children:[{
            title: '分钟',
            dataIndex: 'minminute',
          },{
            title: '起始时点',
            dataIndex: 'minstarttime',
            scopedSlots: { customRender: 'minstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'minendtime',
            scopedSlots: { customRender: 'minendtime' },
          }]
        },
        {
          title: '最长运行时间',
          dataIndex: 'maxtime',
          children:[{
            title: '分钟',
            dataIndex: 'maxminute',
          },{
            title: '起始时点',
            dataIndex: 'maxstarttime',
            scopedSlots: { customRender: 'maxstarttime' },
          },{
            title: '停止时点',
            dataIndex: 'maxendtime',
            scopedSlots: { customRender: 'maxendtime' },
          }]
        },
        {
          title: '累计时间',
          dataIndex: 'time',
          children:[{
            title: '本次累计运行时间(小时)',
            dataIndex: 'thistime',
            width:'80px'
          },{
            title: '上次保养时间',
            dataIndex: 'laterdate',
            scopedSlots: { customRender: 'laterdate' },
          },{
            title: '总运行时间(小时)',
            dataIndex: 'totaltime',
          }]
        },
      ],
      param1:'',
      param2:'',
      param3:'',
      maintainModalVisible: false,
      maintainModalShowType: '',
      maintainDetailData: {
        scenetemplateid: '',
        scenetype: '',
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "变配电系统运行数据"
    },
    breadcrumb() {
      const pages = [{name:"场景管理",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.getTableData()
      }
    },
    modalVisible(val) {
      if(!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
  },
  methods:{
    closeModal(){
      this.modalVisible=false
    },
    getTableData() {
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        scenetemplateid:this.$store.state.scenetemplateid,
        scenetype: 2,
      };
      getSceneRunDataList(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.data1 = res.data1;
          this.data2 = res.data2;
          this.data3 = res.data3;

          // this.data1 = [
          //   {
          //     "minminute": 0.13,
          //     "thistime": 8.025,
          //     "maxminute": 240,
          //     "totaltime": 8.025,
          //     "maxendtime": "2023-03-06T18:44:56.000+08:00",
          //     "num": "1",
          //     "maxstarttime": "2023-03-06T14:44:56.000+08:00",
          //     "name": "1受电开关分闸",
          //     "laterdate": "2021-12-01",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-06T10:43:00.000+08:00",
          //     "minendtime": "2023-03-06T10:43:08.000+08:00"
          //   },
          //   {
          //     "minminute": 0.1,
          //     "thistime": 105.495,
          //     "maxminute": 3810.03,
          //     "totaltime": 105.495,
          //     "maxendtime": "2023-03-05T08:28:46.000+08:00",
          //     "num": "2",
          //     "maxstarttime": "2023-03-02T16:58:44.000+08:00",
          //     "name": "2受电开关分闸",
          //     "laterdate": "2022-12-26",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-06T10:43:06.000+08:00",
          //     "minendtime": "2023-03-06T10:43:12.000+08:00"
          //   },
          //   {
          //     "minminute": 0.48,
          //     "thistime": 498.186,
          //     "maxminute": 28883.92,
          //     "totaltime": 498.186,
          //     "num": "3",
          //     "maxstarttime": "2023-03-08T09:45:55.000+08:00",
          //     "name": "母联开关合闸",
          //     "laterdate": "2022-12-27",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-06T10:44:31.000+08:00",
          //     "minendtime": "2023-03-06T10:45:00.000+08:00"
          //   },
          //   {
          //     "minminute": 0.3,
          //     "thistime": 4.101,
          //     "maxminute": 5.68,
          //     "totaltime": 4.101,
          //     "maxendtime": "2023-03-08T03:18:35.000+08:00",
          //     "num": "4",
          //     "maxstarttime": "2023-03-08T03:12:54.000+08:00",
          //     "name": "发电开关合闸",
          //     "laterdate": "2022-12-26",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-07T06:41:57.000+08:00",
          //     "minendtime": "2023-03-07T06:42:15.000+08:00"
          //   },
          //   {
          //     "minminute": 238.9,
          //     "thistime": 47.869,
          //     "maxminute": 479.07,
          //     "totaltime": 47.869,
          //     "maxendtime": "2023-03-03T08:55:28.000+08:00",
          //     "num": "5",
          //     "maxstarttime": "2023-03-03T00:56:24.000+08:00",
          //     "name": "11电容投入",
          //     "laterdate": "2022-12-26",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-07T14:04:49.000+08:00",
          //     "minendtime": "2023-03-07T18:03:43.000+08:00"
          //   },
          //   {
          //     "minminute": 0.47,
          //     "thistime": 524.377,
          //     "maxminute": 28870.12,
          //     "totaltime": 524.377,
          //     "num": "6",
          //     "maxstarttime": "2023-03-08T09:59:43.000+08:00",
          //     "name": "12电容投入",
          //     "laterdate": "2022-12-26",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-06T11:18:04.000+08:00",
          //     "minendtime": "2023-03-06T11:18:32.000+08:00"
          //   },
          //   {
          //     "minminute": 78.67,
          //     "thistime": 45.948,
          //     "maxminute": 478.93,
          //     "totaltime": 45.948,
          //     "maxendtime": "2023-03-04T15:35:33.000+08:00",
          //     "num": "7",
          //     "maxstarttime": "2023-03-04T07:36:37.000+08:00",
          //     "name": "21电容投入",
          //     "laterdate": "2022-12-26",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-07T12:46:41.000+08:00",
          //     "minendtime": "2023-03-07T14:05:21.000+08:00"
          //   },
          //   {
          //     "minminute": 0.87,
          //     "thistime": 0.132,
          //     "maxminute": 1.3,
          //     "totaltime": 0.132,
          //     "maxendtime": "2023-03-04T23:35:49.000+08:00",
          //     "num": "8",
          //     "maxstarttime": "2023-03-04T23:34:31.000+08:00",
          //     "name": "22电容投入",
          //     "laterdate": "2022-12-24",
          //     "tableame": "开关状态",
          //     "minstarttime": "2023-03-06T20:47:39.000+08:00",
          //     "minendtime": "2023-03-06T20:48:31.000+08:00"
          //   }
          // ]

          // this.data2 = [
          //   {
          //     "tablename":"电量数据",
          //     "num":"1",
          //     "name1":'Uab(V)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Uab(V)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"2",
          //     "name1":'Ubc(V)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Ubc(V)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"3",
          //     "name1":'Uca(V)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Uca(V)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"4",
          //     "name1":'Ia(A)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Ia(A)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"5",
          //     "name1":'Ib(A)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Ib(A)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"6",
          //     "name1":'Ic(A)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'Ic(A)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"7",
          //     "name1":'P(KVA)',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'P(KVA)',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"8",
          //     "name1":'COS',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'COS',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   },
          //   {
          //     "tablename":"电量数据",
          //     "num":"9",
          //     "name1":'变压器负载率',
          //     "mintime1": '2023-03-06 18:44:56',
          //     "minnumber1": '2',
          //     "minhappentime1":'2023-03-06 18:44:56',
          //     "maxtime1":'2023-03-06 18:44:56',
          //     "maxnumber1":'3',
          //     "maxhappentime1":'2023-03-06 18:44:56',
          //     "name2":'变压器负载率',
          //     "mintime2": '2023-03-06 18:44:56',
          //     "minnumber2": '4',
          //     "minhappentime2":'2023-03-06 18:44:56',
          //     "maxtime2":'2023-03-06 18:44:56',
          //     "maxnumber2":'5',
          //     "maxhappentime2":'2023-03-06 18:44:56',
          //   }
          // ]

          // this.data3 = [
          //   {
          //     num: '1',
          //     tablename:'设施状态',
          //     name:'变压器风机',
          //     minminute: '11',
          //     minstarttime:'2023-03-06 18:44:56',
          //     minendtime:'2023-03-06 18:44:56',
          //     maxminute:'123',
          //     maxstarttime:'2023-03-06 18:44:56',
          //     maxendtime:'2023-03-06 18:44:56',
          //     thistime:'123',
          //     laterdate:'2023-03-28',
          //     totaltime:'456',
          //   },
          //   {
          //     num: '2',
          //     tablename:'设施状态',
          //     name:'防潮机',
          //     minminute: '11',
          //     minstarttime:'2023-03-06 18:44:56',
          //     minendtime:'2023-03-06 18:44:56',
          //     maxminute:'123',
          //     maxstarttime:'2023-03-06 18:44:56',
          //     maxendtime:'2023-03-06 18:44:56',
          //     thistime:'123',
          //     laterdate:'2023-03-28',
          //     totaltime:'456',
          //   },
          //   {
          //     num: '3',
          //     tablename:'设施状态',
          //     name:'排风机',
          //     minminute: '11',
          //     minstarttime:'2023-03-06 18:44:56',
          //     minendtime:'2023-03-06 18:44:56',
          //     maxminute:'123',
          //     maxstarttime:'2023-03-06 18:44:56',
          //     maxendtime:'2023-03-06 18:44:56',
          //     thistime:'123',
          //     laterdate:'2023-03-28',
          //     totaltime:'456',
          //   },
          //   {
          //     num: '4',
          //     tablename:'设施状态',
          //     name:'送风机',
          //     minminute: '11',
          //     minstarttime:'2023-03-06 18:44:56',
          //     minendtime:'2023-03-06 18:44:56',
          //     maxminute:'123',
          //     maxstarttime:'2023-03-06 18:44:56',
          //     maxendtime:'2023-03-06 18:44:56',
          //     thistime:'123',
          //     laterdate:'2023-03-28',
          //     totaltime:'456',
          //   },
          // ]
        }
      }).catch(err => {
        this.tableLoading = false;
      })
      getSceneInfoListByCondition({scenetemplateid:this.$store.state.scenetemplateid}).then(res=>{
        if(res && res.returncode == '0') {
          this.param1=res.item[0].param1
          this.param2=res.item[0].param2
          this.param3=res.item[0].param3
        }
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
    },
    SceneMaintainDetail(type){
      this.maintainDetailData.scenetemplateid=this.$store.state.scenetemplateid
      this.maintainDetailData.scenetype=2
      this.maintainModalShowType=type
      this.maintainModalVisible=true
    }
  },
}
</script>
<style scoped>

</style>