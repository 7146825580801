<template>
  <a-modal v-model="modalVisible" title="时间运行图" :width="1250" :centered="true" @cancel="closeModal">
    <template slot="footer">
      <a-button type="primary" @click="closeModal()">关闭</a-button>
    </template>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" :label-col="{span:14}" :wrapper-col="{span:10}">
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 35%;margin-left: -9%">
              <a-range-picker v-model="queryInstallDate" style="width: 300px;"
                              format="YYYY-MM-DD HH:mm:ss"
                              :show-time="{ format: 'HH:mm:ss' }"
                              :placeholder="['开始时间', '结束时间']">
                <template slot="renderExtraFooter">
                  <a-button :type="buttonType" @click="onlyDate">只选日期</a-button>
                </template>
              </a-range-picker>
            </a-form-model-item>
            <span style="font-weight: bold;font-size:15px;color: red;margin-left: 90px;">图表中若无数据,请确认查询时间区间选择是否正确(默认是最近24小时的数据)</span>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container">
      <div class="box-container-inner">
        <div class="table-header">
          <div class="table-btns">
            <span style="color: red;">点击左侧图标可以选择展示哪些曲线</span>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
          <div v-if="ChartType==='low_pump'" class="line-shape" id="line1" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='middle_pump'" class="line-shape" id="line2" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='high_pump'" class="line-shape" id="line3" style="height: 600px;width: 100%"></div>
          <!--          <div v-if="ChartType==='water_tank'" class="line-shape" id="line4" style="height: 400px;width: 100%"></div>-->
          <div v-if="ChartType==='collecting_well_1'" class="line-shape" id="line5" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='fan_1'" class="line-shape" id="line6" style="height: 600px;width: 100%"></div>
          <div v-if="ChartType==='water_room_temperature_humidity'" class="line-shape" id="line7" style="height: 600px;width: 100%"></div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import moment from "moment";
import {mapState} from "vuex";
import * as echarts from 'echarts';
import {getDateTimeRange, getTimeRange} from "U/index";
import {getSceneRunTimeCharts} from "A/monitoring";
import {getEventrecordListByCondition} from "A/wlw";
export default {
  name: "StatusData",
  components: {},
  props: {
    visible: {
      default: false
    },
    ChartType: {
      default: null
    }
  },
  data() {
    return {
      moment,
      buttonType:'',
      modalVisible: false,
      queryInstallDate: null,
      queryParams: {
        starttime: '',
        endtime: '',
      },
      DataList1: [],
      DataList2: [],
      DataList3: [],
      DataList4: [],
      DataList5: [],
      DataList6: [],
      DataList7: [],
      yInterval: 1,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "时间运行图"
    },
    breadcrumb() {
      const pages = [{name: "场景管理", path: ""}]
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.getTableData()
      }
    },
    modalVisible(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    },
    queryInstallDate(val) {
      let {start, end} = getDateTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
  },
  methods: {
    onlyDate(){
      if(!this.queryInstallDate){
        this.$message.error("请先选择日期")
      }else{
        this.buttonType='primary'
        this.queryInstallDate[0]._d=new Date(moment(this.queryInstallDate[0]._d).format("YYYY-MM-DD")+" 00:00:00")
        this.queryInstallDate[1]._d=new Date(moment(this.queryInstallDate[1]._d).format("YYYY-MM-DD")+" 23:59:59")
        let {start, end} = getDateTimeRange(this.queryInstallDate);
        this.queryParams.starttime = start;
        this.queryParams.endtime = end;
      }
    },
    closeModal() {
      this.modalVisible = false
      this.resetQueryParams()
    },
    getTableData() {
      this.DataList1 = []
      this.DataList2 = []
      this.DataList3 = []
      this.DataList4 = []
      this.DataList5 = []
      this.DataList6 = []
      this.DataList7 = []
      this.tableLoading = true;
      if (this.queryParams.starttime === '') {
        this.queryParams.starttime = moment((new Date().getTime() - 86400000)).format("YYYYMMDDHHmmss")
      }
      if (this.queryParams.endtime === '') {
        this.queryParams.endtime = moment(new Date()).format("YYYYMMDDHHmmss")
      }
      let params = {
        ...this.queryParams,
        scenetemplateid:this.$store.state.scenetemplateid,
        type: this.ChartType,
      };
      getSceneRunTimeCharts(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode === '0') {
          if (this.ChartType === "low_pump" || this.ChartType === "middle_pump" || this.ChartType === "high_pump") {
            if (res.data1.length > 0 && res.data1[0].ydata === "0") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[0].ydata === "1") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 1])
            } else {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            }
            for (let i = 0; i < res.data1.length; i++) {
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
            }
            if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "1") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "0") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 1])
            }
            if (res.data2.length > 0 && res.data2[0].ydata === "0") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[0].ydata === "1") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 3])
            } else {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            }
            for (let i = 0; i < res.data2.length; i++) {
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata == 0 ? 2 : 3])
            }
            if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "1") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "0") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 3])
            }
            if (res.data3.length > 0 && res.data3[0].ydata === "0") {
              this.DataList3.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 4])
            } else if (res.data3.length > 0 && res.data3[0].ydata === "1") {
              this.DataList3.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 5])
            } else {
              this.DataList3.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 4])
              this.DataList3.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 4])
            }
            for (let i = 0; i < res.data3.length; i++) {
              this.DataList3.push([moment(res.data3[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data3[i].ydata == 0 ? 4 : 5])
            }
            if (res.data3.length > 0 && res.data3[res.data3.length - 1].ydata === "1") {
              this.DataList3.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 4])
            } else if (res.data3.length > 0 && res.data3[res.data3.length - 1].ydata === "0") {
              this.DataList3.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 5])
            }
            if (res.data4.length > 0 && res.data4[0].ydata === "0") {
              this.DataList4.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 6])
            } else if (res.data4.length > 0 && res.data4[0].ydata === "1") {
              this.DataList4.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 7])
            } else {
              this.DataList4.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 6])
              this.DataList4.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 6])
            }
            for (let i = 0; i < res.data4.length; i++) {
              this.DataList4.push([moment(res.data4[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data4[i].ydata == 0 ? 6 : 7])
            }
            if (res.data4.length > 0 && res.data4[res.data4.length - 1].ydata === "1") {
              this.DataList4.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 6])
            } else if (res.data4.length > 0 && res.data4[res.data4.length - 1].ydata === "0") {
              this.DataList4.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 7])
            }
            if (res.data5.length > 0 && res.data5[0].ydata === "0") {
              this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 8])
            } else if (res.data5.length > 0 && res.data5[0].ydata === "1") {
              this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 9])
            } else {
              getEventrecordListByCondition({eventcode:"9030501"}).then(res=>{
                if(res&&res.returncode==='0'&&res.item.length>0){
                  if(res.item[0].eventstatus!==2){
                    this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 9])
                    this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 9])
                  }else{
                    this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 8])
                    this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 8])
                  }
                }else{
                  this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 8])
                  this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 8])
                }
              })
              // this.DataList5.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 8])
              // this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 8])
            }
            for (let i = 0; i < res.data5.length; i++) {
              this.DataList5.push([moment(res.data5[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data5[i].ydata == 0 ? 8 : 9])
            }
            if (res.data5.length > 0 && res.data5[res.data5.length - 1].ydata === "1") {
              this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 8])
            } else if (res.data5.length > 0 && res.data5[res.data5.length - 1].ydata === "0") {
              this.DataList5.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 9])
            }
            if (res.data6.length > 0 && res.data6[0].ydata === "0") {
              this.DataList6.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 10])
            } else if (res.data6.length > 0 && res.data6[0].ydata === "1") {
              this.DataList6.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 11])
            } else {
              this.DataList6.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 10])
              this.DataList6.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 10])
            }
            for (let i = 0; i < res.data6.length; i++) {
              this.DataList6.push([moment(res.data6[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data6[i].ydata == 0 ? 10 : 11])
            }
            if (res.data6.length > 0 && res.data6[res.data6.length - 1].ydata === "1") {
              this.DataList6.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 10])
            } else if (res.data6.length > 0 && res.data6[res.data6.length - 1].ydata === "0") {
              this.DataList6.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 11])
            }
            if (res.data7.length > 0 && res.data7[0].ydata === "0") {
              this.DataList7.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 12])
            } else if (res.data7.length > 0 && res.data7[0].ydata === "1") {
              this.DataList7.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 13])
            } else {
              this.DataList7.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 12])
              this.DataList7.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 12])
            }
            for (let i = 0; i < res.data7.length; i++) {
              this.DataList7.push([moment(res.data7[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data7[i].ydata == 0 ? 12 : 13])
            }
            if (res.data7.length > 0 && res.data7[res.data7.length - 1].ydata === "1") {
              this.DataList7.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 12])
            } else if (res.data7.length > 0 && res.data7[res.data7.length - 1].ydata === "0") {
              this.DataList7.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 13])
            }
            let that = this
            if (this.ChartType === "low_pump") {
              setTimeout(() => {
                that.initCharts1()
              }, 1000)
            } else if (this.ChartType === "middle_pump") {
              setTimeout(() => {
                that.initCharts2()
              }, 1000)
            } else if (this.ChartType === "high_pump") {
              setTimeout(() => {
                that.initCharts3()
              }, 1000)
            }
          } else if (this.ChartType === "collecting_well_1") {
            if (res.data1.length > 0 && res.data1[0].ydata === "0") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[0].ydata === "1") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 1])
            } else {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            }
            for (let i = 0; i < res.data1.length; i++) {
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
            }
            if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "1") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "0") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 1])
            }
            if (res.data2.length > 0 && res.data2[0].ydata === "0") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[0].ydata === "1") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 3])
            } else {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            }
            for (let i = 0; i < res.data2.length; i++) {
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata == 0 ? 2 : 3])
            }
            if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "1") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "0") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 3])
            }
            let that = this
            setTimeout(() => {
              that.initCharts5()
            }, 500)
          } else if (this.ChartType === "fan_1") {
            if (res.data1.length > 0 && res.data1[0].ydata === "0") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[0].ydata === "1") {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 1])
            } else {
              this.DataList1.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 0])
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            }
            for (let i = 0; i < res.data1.length; i++) {
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
            }
            if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "1") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 0])
            } else if (res.data1.length > 0 && res.data1[res.data1.length - 1].ydata === "0") {
              this.DataList1.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 1])
            }
            if (res.data2.length > 0 && res.data2[0].ydata === "0") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[0].ydata === "1") {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 3])
            } else {
              this.DataList2.push([moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), 2])
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            }
            for (let i = 0; i < res.data2.length; i++) {
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata == 0 ? 2 : 3])
            }
            if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "1") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 2])
            } else if (res.data2.length > 0 && res.data2[res.data2.length - 1].ydata === "0") {
              this.DataList2.push([moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), 3])
            }
            let that = this
            setTimeout(() => {
              that.initCharts6()
            }, 500)
          }else if(this.ChartType ==='water_room_temperature_humidity'){
            for(let i=0;i<res.data1.length;i++){
              this.DataList1.push([moment(res.data1[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data1[i].ydata])
              this.DataList2.push([moment(res.data2[i].xdata).format("YYYY-MM-DD HH:mm:ss"), res.data2[i].ydata])
            }
            let that = this
            setTimeout(() => {
              that.initCharts7()
            }, 500)
          }
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.queryInstallDate = [];
      this.buttonType=''
      this.DataList1 = []
      this.DataList2 = []
      this.DataList3 = []
      this.DataList4 = []
      this.DataList5 = []
    },
    //低区水泵组
    initCharts1() {
      let chartDom = document.getElementById('line1');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "低区水泵组时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 13,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '主泵1'
                } else if (value === 3) {
                  return '主泵2'
                } else if (value === 5) {
                  return '主泵3'
                } else if (value === 7) {
                  return '稳压泵'
                } else if (value === 9) {
                  return '水箱补水'
                } else if (value === 11) {
                  return '管网超压'
                } else if (value === 13) {
                  return '管网失压'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '管网失压',
            type: 'line',
            step: 'start',
            data: this.DataList7,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '管网超压',
            type: 'line',
            step: 'start',
            data: this.DataList6,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '水箱补水',
            type: 'line',
            step: 'start',
            data: this.DataList5,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '稳压泵',
            type: 'line',
            step: 'start',
            data: this.DataList4,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵3',
            type: 'line',
            step: 'start',
            data: this.DataList3,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵2',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵1',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //中区水泵组
    initCharts2() {
      let chartDom = document.getElementById('line2');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "中区水泵组时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 13,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '主泵1'
                } else if (value === 3) {
                  return '主泵2'
                } else if (value === 5) {
                  return '主泵3'
                } else if (value === 7) {
                  return '稳压泵'
                } else if (value === 9) {
                  return '水箱补水'
                } else if (value === 11) {
                  return '管网超压'
                } else if (value === 13) {
                  return '管网失压'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '管网失压',
            type: 'line',
            step: 'start',
            data: this.DataList7,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '管网超压',
            type: 'line',
            step: 'start',
            data: this.DataList6,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '水箱补水',
            type: 'line',
            step: 'start',
            data: this.DataList5,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '稳压泵',
            type: 'line',
            step: 'start',
            data: this.DataList4,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵3',
            type: 'line',
            step: 'start',
            data: this.DataList3,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵2',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵1',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //高区水泵组
    initCharts3() {
      let chartDom = document.getElementById('line3');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "高区水泵组时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 13,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '主泵1'
                } else if (value === 3) {
                  return '主泵2'
                } else if (value === 5) {
                  return '主泵3'
                } else if (value === 7) {
                  return '稳压泵'
                } else if (value === 9) {
                  return '水箱补水'
                } else if (value === 11) {
                  return '管网超压'
                } else if (value === 13) {
                  return '管网失压'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '管网失压',
            type: 'line',
            step: 'start',
            data: this.DataList7,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '管网超压',
            type: 'line',
            step: 'start',
            data: this.DataList6,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '水箱补水',
            type: 'line',
            step: 'start',
            data: this.DataList5,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '稳压泵',
            type: 'line',
            step: 'start',
            data: this.DataList4,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵3',
            type: 'line',
            step: 'start',
            data: this.DataList3,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵2',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '主泵1',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //水箱补水（并入水泵组中，此代码暂时不用）
    initCharts4() {
      let chartDom = document.getElementById('line4');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "水箱补水时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 1,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '水箱'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '水箱补水',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //1#集水井
    initCharts5() {
      let chartDom = document.getElementById('line5');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "1#集水井时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 3,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '潜水泵1'
                } else if (value === 3) {
                  return '潜水泵2'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '潜水泵2',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '潜水泵1',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //风机
    initCharts6() {
      let chartDom = document.getElementById('line6');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "风机时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return ''
            // return params[0].axisValueLabel + "<br/>" + params[0].seriesName
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '启停',
            min: 0,
            max: 3,
            interval: this.yInterval,
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: function (value) {
                if (value === 1) {
                  return '送风机1'
                } else if (value === 3) {
                  return '排风机1'
                } else {
                  return ''
                }
              }
            },
          },
        ],
        series: [
          {
            name: '排风机1',
            type: 'line',
            step: 'start',
            data: this.DataList2,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
          {
            name: '送风机1',
            type: 'line',
            step: 'start',
            data: this.DataList1,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value.toString().substring(10, 19)
                }
              }
            },
          },
        ]
      };
      option && myChart.setOption(option);
    },
    //温湿度
    initCharts7() {
      let chartDom = document.getElementById('line7');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          x: "left",
          show: true, //是否显示
          text: "温湿度时间运行图",
          textStyle: {
            color: "green", // 主标题文字的颜色。
            fontSize: 18, // 字体大小
            fontWeight: 'bold',
          },
          textAlign: "left",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              color: '#e20937'
            }
          },
          formatter: function (params) {
            return params[0].axisValueLabel
                + (params.length>=1?("<br/>" +params[0].seriesName+"："+params[0].data[1]+"%"):"")
                + (params.length>=2?("<br/>" +params[1].seriesName+"："+params[1].data[1]+"℃"):"")
          },
        },
        toolbox: {
          feature: {},
          iconStyle: {
            normal: {
              color: '#000000',//设置颜色
            }
          }
        },
        legend: {},
        xAxis: [
          {
            type: "time",
            name: '时间',
            interval: 60 * 1000, // 固定x轴时间间隔
            min: moment(this.queryParams.starttime + "000000", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 开始时间时间戳
            max: moment(this.queryParams.endtime + "235959", "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"), // 结束时间时间戳 如果实际的最大日期不确定，也可以不设定这个属性
            axisLabel: {
              show: true,
              showMinLabel: true,
              showMaxLabel: true,
              textStyle: {
                color: '#000000',
                fontWeight: 'bold',
              },
              formatter: function (data) {
                return moment(data).format("HH:mm:ss") + "\n" + moment(data).format("MM-DD");
              }
            },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '温度(℃)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: '{value} ℃'
            },
          },
          {
            type: 'value',
            name: '湿度(%)',
            // 整条y轴
            axisLine: {
              show: true
            },
            // y轴上的小横线
            axisTick: {
              show: true
            },
            axisLabel: { //y轴文字
              show: true,//是否显示Y轴
              textStyle: {
                color: 'blue',
                fontSize: 16
              },
              formatter: '{value} %'
            },
          },
        ],
        series: [
          {
            name: '湿度',
            type: 'line',
            yAxisIndex:1,
            data: this.DataList2,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"%"
            //     }
            //   }
            // },
          },
          {
            name: '温度',
            type: 'line',
            yAxisIndex:0,
            data: this.DataList1,
            // label: {
            //   normal: {
            //     show: true,
            //     formatter: function (params) {
            //       return params.value[1]+"℃"
            //     }
            //   }
            // },
          },
        ]
      };
      option && myChart.setOption(option);
    },
  },
}
</script>
<style lang="scss" scoped>
.line-shape{
  margin-bottom: 0px;
  pointer-events: auto;
}
</style>