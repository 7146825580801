<!--AI+物联网故障历史页面-->
<template>
  <div :title="pageTitle" :breadcrumb="breadcrumb">
    <div v-if="false" class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
<!--          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">-->
            <!-- <a-form-model-item label="电梯注册码" prop="liftnum">
              <a-input v-model.trim="queryParams.liftnum" placeholder="请输入"></a-input>
            </a-form-model-item> -->
<!--            <a-form-model-item label="救援码" prop="liftrescue">-->
<!--              <a-input v-model.trim="queryParams.liftrescue" placeholder="请输入"></a-input>-->
<!--            </a-form-model-item>-->
            <!-- <a-form-model-item label="地址" prop="adress">
              <a-input v-model.trim="queryParams.adress" placeholder="请输入"></a-input>
            </a-form-model-item> -->
<!--            <div class="query-btns">-->
<!--              <a-button @click="resetQueryParams">重置</a-button>-->
<!--              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>-->
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button> -->
<!--            </div>-->
<!--          </a-form-model>-->
        </div>
      </div>
    </div>
    <div class="box-container" :style="liftid?'':'margin-top: 10px; padding: 0;'">
      <div class="box-container-inner" :style="liftid?'':'padding: 0;'">
        <!-- <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div> -->
        <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
<!--          <span slot="eventname" :title="value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''" slot-scope="value, record">{{value?(value+(record.thirdeventname?('('+record.thirdeventname+')'):'')):''}}</span>-->
          <span slot="eventstatus" slot-scope="value">
            <a-tag :color="eventstatusColors[value]||''">{{eventstatus[value]||''}}</a-tag>
          </span>
          <span slot="eventcode" slot-scope="value, record">
            <span>{{record.devicenum?alarmTypesMap[value]+'(物联网)':alarmTypesMap[value]}}</span>
          </span>
          <span slot="eventname" slot-scope="value, record" :title="value">
            <span>{{value}}</span>
          </span>
          <span slot="eventchildname" slot-scope="value, record" :title="value">
            <span>{{value}}</span>
          </span>
          <span slot="eventstarttime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="eventendtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="duration" slot-scope="value" :title="value?value+'分钟':''">
            <span>{{value?value+'分钟':''}}</span>
          </span>
          <span slot="confirmtime" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="check" slot-scope="value, record">
            <a-button type="link" size="small" @click="checkCalllog(value,record)">播放</a-button>
          </span>
          <span slot="image" slot-scope="value, record">
            <a-button v-if="record.devicenum&&record.showimage==='1'" type="link" size="small" @click="lookImage(value,record)">图片</a-button>
          </span>
          <span slot="recover" slot-scope="value, record">
            <a-button v-if="record.devicenum" type="link" size="small" @click="recover(value,record)">
              <span>回放</span>
            </a-button>
          </span>
          <span slot="detail" slot-scope="value, record">
            <a-button type="link" size="small" @click="detail(value,record)">
              <span>详情</span>
            </a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <imageBack :visible.sync="imageBackVisible" ref="imageBack" :EventData="eventData"></imageBack>
    <checkTraceBack :visible.sync="checkTraceVisible" ref="checkTrace" :Datadetail="detaildata"></checkTraceBack>
<!--    <event-record-list :visible.sync="eventModalVisible" :show-type="eventModalShowType" :detailData="detaildata"></event-record-list>-->
    <a-modal :visible="detailVisible" :title="detailParams.eventtype==902?'异常历史详情':'报警历史详情'" @cancel="detailVisible=false" :footer="null" :width="1200">
      <a-form-model ref="queryForm" :model="detailParams" layout="inline" class="query-form">
        <a-form-model-item label="事件编号" prop="eventnum" style="width: 30%">
          <a-input placeholder="" style="width: 200px;" v-model="detailParams.eventnum" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="系统名称(场景)" prop="systemnameid" style="width: 30%">
          <a-select default-value="" v-model="detailParams.systemnameid" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in systemNames" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="报警时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.eventstarttime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="报警类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902&&(detailParams.systemnameid==7||detailParams.systemnameid==8)" label="报警子类型" prop="eventchildcode" style="width: 30%">
          <a-select v-model="detailParams.eventchildcode" placeholder="" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in alarmChildTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="事件类型" prop="eventcode" style="width: 30%">
          <a-select v-model="detailParams.eventcode" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in abnormalTypes" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="重大影响" prop="biginfluence" style="width: 30%">
          <a-select v-model="detailParams.biginfluence" style="width: 200px;" :disabled="true">
            <a-select-option v-for="(item, index) in bigInfluences" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="管理类型" prop="managetype" style="width: 30%">
          <a-input v-model.trim="detailParams.managetype" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="位置/编码" prop="location" style="width: 30%">
          <a-input v-model="detailParams.location" placeholder="" style="width: 195px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="记录时间" prop="eventstarttime" style="width: 30%">
          <a-date-picker v-model="detailParams.eventstarttime" show-time :disabled="true" placeholder="" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype==902" label="异常位置" prop="location" style="width: 30%">
          <a-input v-model.trim="detailParams.location" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场人员" prop="arriveman" style="width: 30%">
          <a-input v-model="detailParams.arriveman" placeholder="" style="width: 200px;" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场时间" prop="arrivetime" style="width: 30%">
          <a-date-picker show-time placeholder="" v-model="detailParams.arrivetime" style="width: 200px;" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="确警时长" prop="timerange" style="width: 30%">
          <a-input v-model="detailParams.timerange" addon-after="分钟" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="到场反馈" prop="feedback" style="width: 30%">
          <a-select v-model="detailParams.feedback" placeholder="" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in feedbackList" :key="index" :value="item.dickey">{{item.dicvalue}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修" prop="reports" style="width: 30%">
          <a-select v-model="detailParams.reports" placeholder="请选择" style="width: 200px;" :disabled="true">
            <a-select-option value="无">无</a-select-option>
            <a-select-option value="报事">报事</a-select-option>
            <a-select-option value="报修">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="报事/报修单号" prop="repairorder" style="width: 30%">
          <a-input v-model="detailParams.repairorder" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item label="计划性作业" prop="plannedwork" style="width: 30%">
          <a-select v-model="detailParams.plannedwork" placeholder="请选择" style="width: 125px" :disabled="true">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="情况描述" prop="description" style="width: 30%">
          <a-textarea v-model.trim="detailParams.description" placeholder="请输入" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="处理措施" prop="measure" style="width: 30%">
          <a-textarea v-model.trim="detailParams.measure" placeholder="请输入" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-divider />
        <a-form-model-item label="跟进结果" prop="followreturn" style="width: 30%">
          <a-textarea v-model.trim="detailParams.followreturn" placeholder="请输入" cols="32" rows="2" :disabled="true"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="完成标志" prop="eventstatus" style="width: 30%">
          <a-select default-value="" v-model="detailParams.eventstatus" style="width: 200px" :disabled="true">
            <a-select-option v-for="(item, index) in finishSigns" :key="index" :value="item.key">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventstatus==2" label="确认人" prop="confirmman" style="width: 30%">
          <a-input v-model="detailParams.confirmman" placeholder="" style="width: 200px" :disabled="true"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventstatus==2" label="确认时间" prop="confirmtime" style="width: 30%">
          <a-date-picker v-model="detailParams.confirmtime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventstatus==2" label="处理时长" prop="processtime" style="width: 30%">
          <a-input v-model="detailParams.processtime" addon-after="分钟" placeholder="" :disabled="true" style="width: 200px"></a-input>
        </a-form-model-item>
        <a-form-model-item v-if="detailParams.eventtype!=902" label="记录时间" prop="writetime" style="width: 30%">
          <a-date-picker v-model="detailParams.writetime" show-time placeholder="" :disabled="true" style="width: 200px;"></a-date-picker>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import {getCache, getItemFromArrayByKey, getTimeRange} from 'U'
import store from '@/store'
import { mapState } from 'vuex'
import moment from 'moment'
import imageBack from "V/jcxx/monitorfile/timeMonitoring/imageBack";
import pagination from '@/mixins/pagination'
import approvestatusMap from '@/json/approvestatus'
import approvestatuscolors from '@/json/approvestatuscolors'
import {
  titleLists,
  eventstatus,
  eventstatusColors,
  finishSigns,
} from '@/json/wlwhistory'
import {getMentenacegroupListByCondition} from 'A/xtpz.js'
import { deleteEventrecord, modifyEventrecord} from 'A/wlw.js'
import {addEventrecord} from "A/thjlcx";
import checkTraceBack from "V/abnormalHistory/wlwbj/main/checkTraceBack";
import {getMoreEventrecordListByCondition} from "A/wlw";
import {getUnhandleIotEventrecord} from "A/monitoring";
export default {
  name: 'wlwBasicPart',
  mixins: [pagination],
  components: {
    imageBack,
    checkTraceBack,
  },
  props: ['pageType', 'devicenum', 'monitorpointnum', 'liftid','list'],
  data() {
    return {
      moment,
      disabled:false,
      detailVisible:false,
      imageBackVisible:false,
      devicenums:'',
      eventData:'',
      eventtype:'',
      systemNames:[],
      bigInfluences:[],
      alarmTypes:[],
      alarmTypesMap:{},
      alarmChildTypes:[],
      abnormalTypes:[],
      feedbackList:[],
      finishSigns,
      eventstatus,
      eventstatusColors,
      approvestatusMap,
      approvestatuscolors,
      showAdvanced: false,
      eventModalVisible:false,
      eventModalShowType:'',
      detailParams:{},
      queryParams: {
        userdepid: '',
        address: '',
        areacode: '',
        starttime: '',
        endtime: '',
      },
      queryAreaSelected: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      maintenancedepidCascaderSelected: [],
      maintenancegroupidOptions: [],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      operationLiftid: '',
      // checkModalVisible: false,
      checklistModalVisible: false,
      examineModalVisible: false,
      examinelistModalVisible: false,
      pagination: {
        total: 0,
        pageSize: this.liftid ? 10 : 5,
      },
      checkTraceVisible:false,
      detaildata:'',
      sortedInfo: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return titleLists[this.pageType]
    },
    tableColumns(){
      let { sortedInfo } = this;
      sortedInfo = sortedInfo || {};
      return [
        ...(this.pageType=='alarm'||this.pageType=='iotAlarm')?[{
          title: '报警名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        ...(this.pageType=='iotAlarm')?[{
          title: '报警子类型',
          dataIndex: 'eventchildname',
          key: 'eventchildname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventchildname' }
        }]:[],
        ...(this.pageType=='abnormal')?[{
          title: '异常名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        ...(this.pageType=='incident'||this.pageType=='communicate')?[{
          title: '事件名称',
          dataIndex: 'eventname',
          key: 'eventname',
          ellipsis: true,
          scopedSlots: { customRender: 'eventname' }
        }]:[],
        ...(this.pageType=='incident')?[{
          title: '运行时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          scopedSlots: { customRender: 'duration' },
        }]:[],
        ...(this.pageType=='communicate')?[{
          title: '通话时长',
          dataIndex: 'duration',
          key: 'duration',
          ellipsis: true,
          scopedSlots: { customRender: 'duration' },
        }]:[],
        ...(this.pageType=='alarm'||this.pageType=='iotAlarm'||this.pageType=='communicate')?[{
          title: '报警时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          // width:137,
          // sorter: (a, b) => a.eventstarttime - b.eventstarttime,
          // sortOrder: sortedInfo.columnKey === 'eventstarttime' && sortedInfo.order,
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
        }]:[],
        ...(this.pageType=='abnormal')?[{
          title: '异常时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          // width:137,
          // sorter: (a, b) => a.eventstarttime - b.eventstarttime,
          // sortOrder: sortedInfo.columnKey === 'eventstarttime' && sortedInfo.order,
          ellipsis: true,
          scopedSlots: { customRender: 'eventstarttime' },
        }]:[],
        ...(this.pageType=='incident')?[{
          title: '上报时间',
          dataIndex: 'eventstarttime',
          key: 'eventstarttime',
          ellipsis: true,
          // width:137,
          scopedSlots: { customRender: 'eventstarttime' }
        }]:[],
        ...(this.pageType=='incident'||this.pageType=='communicate')?[{
          title: '结束时间',
          dataIndex: 'eventendtime',
          key: 'eventendtime',
          ellipsis: true,
          // width:137,
          scopedSlots: { customRender: 'eventendtime' }
        }]:[],
        ...(this.pageType=='alarm'||this.pageType=='abnormal')?[{
          title: '结束时间',
          dataIndex: 'confirmtime',
          key: 'confirmtime',
          ellipsis: true,
          // width:137,
          scopedSlots: { customRender: 'confirmtime' }
        }]:[],
        {
          title: '处理状态',
          dataIndex: 'eventstatus',
          key: 'eventstatus',
          ellipsis: true,
          width:70,
          scopedSlots: { customRender: 'eventstatus' }
        },
        ...(this.pageType==='communicate')?[{
          title: '录音',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'check' }
        }]:[],
        ...(this.pageType=='alarm'||this.pageType=='iotAlarm'||this.pageType=='communicate'||this.pageType=='incident')?[{
          title: '图片',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'image' }
        }]:[],
        ...(this.pageType=='alarm'||this.pageType=='iotAlarm'||this.pageType=='communicate'||this.pageType=='incident')?[{
          title: '回放',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'recover' }
        }]:[],
        ...(this.pageType=='alarm'||this.pageType=='abnormal')?[{
          title: '详情',
          align:'center',
          width:50,
          scopedSlots: { customRender: 'detail' }
        }]:[],
        // {
        //   title: '详情',
        //   align:'center',
        //   width:50,
        //   scopedSlots: { customRender: 'detail' }
        // },
      ]
    },
    breadcrumb() {
      const pages = [{name:titleLists.main,path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch: {
    queryAreaSelected(val) {
      if(val && val.length) {
        this.queryParams.areacode = val[val.length-1];
      }else {
        this.queryParams.areacode = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
        this.initMaintenancegroupidOptions();
      }else {
        this.queryParams.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    const keepAlivePages = ['elevatorArchivesDetail', 'timeMonitoring'];
    if(!toTab || keepAlivePages.indexOf(from.name)<0) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    let dictionary = getCache('dictionary', true);
    if(dictionary) {
      this.bigInfluences = dictionary.bigInfluences;
      this.alarmTypes = dictionary.alarmTypes;
      this.alarmTypesMap = dictionary.alarmTypesMap;
      this.alarmChildTypes = dictionary.alarmChildTypes;
      this.systemNames = dictionary.systemNames;
      this.systemNamesMap = dictionary.systemNamesMap;
      this.feedbackList = dictionary.feedbackList;
      this.abnormalTypes = dictionary.abnormalTypes;
      this.abnormalTypesMap = dictionary.abnormalTypesMap;
      this.equipmentTypes = dictionary.equipmentTypes;
      this.manageTypes = dictionary.manageTypes;
      this.recordTypes = dictionary.recordTypes;
    }
  },
  methods: {
    init() {
      if(this.list && this.list.length) {
        this.list.forEach(item => {
          this.devicenums += item.monitorid+"|";
        })
      }
      this.getTableData(true);
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.maintenancedepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    setTimeSort() {
      this.sortedInfo = {
        order: 'descend',
        columnKey: 'eventstarttime',
      };
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      if(this.pageType=='iotAlarm'){
        let params = {
          ...this.queryParams,
          monitorpointnum:this.monitorpointnum,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getUnhandleIotEventrecord(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
            // this.setTimeSort();
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(this.pageType=='communicate'){
        let params = {
          ...this.queryParams,
          eventtype:904,
          history:true,
          batterycar:false,
          monitorpointnum:this.monitorpointnum,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMoreEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else if(this.pageType=='incident'){
        let params = {
          ...this.queryParams,
          eventtype:903,
          history:true,
          batterycar:false,
          monitorpointnum:this.monitorpointnum,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMoreEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }else{
        if(this.pageType=='alarm'){
          this.eventtype=901
        }else if(this.pageType=='abnormal'){
          this.eventtype=902
        }
        let params = {
          ...this.queryParams,
          eventtype:this.eventtype,
          history:true,
          batterycar:false,
          monitorpointnum:this.monitorpointnum,
          // devicenum:this.devicenum==""?this.devicenums:this.devicenum,
          pageno: this.currentPage,
          pagesize: this.pagination.pageSize
        };
        getMoreEventrecordListByCondition(params).then(res => {
          this.tableLoading = false;
          if(res && res.returncode == '0') {
            this.tableData = res.item;
            this.pagination.total = res.count;
            // this.setTimeSort();
          }
        }).catch(err => {
          this.tableLoading = false;
        })
      }
    },
    handleAscend(value,record){
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.eventrecordid);
      }).catch(()=>{
      });
    },
    delete(eventrecordid) {
      this.showLoading();
      if(eventrecordid) {
        let params = {
          eventrecordid
        };
        deleteEventrecord(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      if(type == 'detail') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'elevatorArchivesDetail',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else {
        this.modalShowType = type;
        if(type == 'add') {
          this.modalDetailData = null;
        }else {
          this.modalDetailData = record;
        }
        this.modalVisible = true;
      }
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryParams.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let liftid = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'liftid', liftid);
      if(type == 'detail') {
        this.showModal('detail', liftid, record)
      }else if(type == 'monitoring') {
        let prePages = [
          {
            name: '基础功能',
            path: ''
          }
        ];
        if(this.pageType == 'draft') {
          prePages.push({
            name: '电梯草稿',
            path: 'elevatorDraft'
          })
        }else {
          prePages.push({
            name: '电梯档案',
            path: 'elevatorArchives'
          })
        }
        this.$router.push({
          name: 'timeMonitoring',
          params: {
            liftid: record.liftid,
            prePages: prePages
          }
        })
      }else if(type == 'edit') {
        this.showModal('edit', liftid, record)
      }else if(type == 'delete') {
        this.deleteConfirm(liftid, record)
      }else {
        this.operationLiftid = liftid;
        this[type+'ModalVisible'] = true;
      }
    },
    detail(value,record){
      record.eventstarttime=record.eventstarttime?moment(record.eventstarttime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      record.eventendtime=record.eventendtime?moment(record.eventendtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      record.confirmtime=record.confirmtime?moment(record.confirmtime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      record.writetime=record.writetime?moment(record.writetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      record.arrivetime=record.arrivetime?moment(record.arrivetime,'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):null
      record.biginfluence=record.biginfluence?record.biginfluence.toString():null
      record.systemnameid=record.systemnameid?record.systemnameid.toString():null
      record.feedback=record.systemnameid?record.feedback.toString():null
      this.detailParams=record
      this.detailVisible=true
    },
    checkCalllog(value,record){
      this.$refs.checkTrace.queryParams.caller = record.ipnetnum
      this.detaildata=record;
      this.checkTraceVisible = true
    },
    lookImage(value,record){
      this.eventData=record;
      this.imageBackVisible=true;
    },
    recover(value,record){
      if(record.eventendtime==''||record.eventendtime==null){
        this.eventendtime=record.eventstarttime
        let time = {
          eventrecordid: record.eventrecordid,
          devicenums:record.devicenum,
          starttime:record.eventstarttime,
          endtime: this.eventendtime,
        }
        this.$emit('upDate',time)
      }else{
        let time = {
          eventrecordid: record.eventrecordid,
          devicenums:record.devicenum,
          starttime:record.eventstarttime,
          endtime: record.eventendtime,
        }
        this.$emit('upDate',time)
      }
    },
    transform(value,record){
      let params={
        devicenum:record.devicenum,
        eventcode:'100',
        eventtype:1,
        eventstarttime:record.eventstarttime,
        eventendtime:record.eventendtime,
        eventstatus: 2,
      }
      addEventrecord(params).then(res=>{
        if(res&&res.returncode=='0'){
          modifyEventrecord({eventrecordid:record.eventrecordid,devicenum:record.devicenum,eventcode:record.eventcode,eventtype:record.eventtype,isturned:'1'}).then(res=>{
            if(res&&res.returncode=='0'){
              this.$message.success("转化成功")
              this.getTableData()
            }
          })
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
</style>